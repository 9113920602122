import React from "react";
import { Switch } from "antd";
import Input from "../../shared/Input";

const SwitchInput = ({
  hasToggle,
  switchLabel,
  checked,
  onChangeSwitch,
  value,
  onChange,
  type,
  label,
}) => {
  return (
    <div className="relative">
      {hasToggle && (
        <div className="absolute bottom-0 translate-y-[70%] right-[12px] z-10 flex items-center justify-end gap-2">
          <p className="text-xs">{switchLabel}</p>
          <div className="trkisswitch">
          <Switch checked={checked} onChange={onChangeSwitch} size="small" />
          </div>
        </div>
      )}
      <Input label={label} type={type} value={value} onChange={onChange} />
    </div>
  );
};

export default SwitchInput;
