import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { UploadIcon } from "../../../../assets/Svgs/UploadIcon";
import { useMessageContext } from "../../../../context/messageContext";
import UploadToCloudService from "../../../../service/UploadToCloudService";

const MediaUpload = forwardRef(
  (
    {
      full = false,
      url,
      setUrl,
      textSrcNotThere,
      textSrcThere,
      id,
      type = "image",
      emptyCheck = true,
    },
    ref
  ) => {
    const { showMessage, clearMessage } = useMessageContext();
    const typeOptions = {
      image: {
        allowedFormats: ["image/jpeg", "image/png", "image/jpg"],
        maxFileSizeInMB: 10,
        resourceType: "image",
        wrapperClass: full
          ? "w-full primary:w-1/2 m-auto h-[200px]"
          : "w-full h-[200px]",
      },
      avatar: {
        allowedFormats: ["image/jpeg", "image/png", "image/jpg"],
        maxFileSizeInMB: 10,
        resourceType: "image",
        wrapperClass: "h-16 w-16",
      },
      video: {
        allowedFormats: ["video/mp4", "video/quicktime"],
        maxFileSizeInMB: 500,
        resourceType: "video",
        wrapperClass: full
          ? "w-full primary:w-1/2 m-auto aspect-video"
          : "w-full aspect-video",
      },
    };
    const maxFileSizeInBytes = typeOptions[type].maxFileSizeInMB * 1024 * 1024;

    // sanitize
    const cleanFile = (file) => {
      if (!file) {
        showMessage("error", "Datei ungültig");
        return false;
      }

      if (!typeOptions[type].allowedFormats.includes(file.type)) {
        showMessage("error", "Ungültiges Dateiformat.");
        return false;
      }

      if (file.size > maxFileSizeInBytes) {
        showMessage(
          "error",
          `Die Dateigröße überschreitet das maximale Limit von ${typeOptions[type].maxFileSizeInMB} MB.`
        );
        return false;
      }

      // probably a blob
      if (!file.name) return file;

      // Create a cleaned file name
      const cleanedFileName = file.name.trim().replace(/[^\w\d]/g, "_");

      // Create a new File object with the cleaned file name
      const cleanedFile = new File([file], cleanedFileName, {
        type: file.type,
      });

      // Return the new File object
      return cleanedFile;
    };

    function generateOptimizedUrl(publicId, type) {
      let extension = "";
      switch (type) {
        case "video":
          extension = ".mp4";
          break;
        case "image":
          extension = ".webP";
          break;
      }
      const cloudName = "dcwsmnhb9"; // Dein Cloudinary-Cloudname
      // const transformations = "w_1500,q_auto"; // Transformationen
      return `https://res.cloudinary.com/${cloudName}/${type}/upload/${publicId}${extension}`;
    }

    /**
     * State
     */
    const [imgInBackend, setImageInBackend] = useState(url);
    const currentSrcIsNotValid = useMemo(() => url === "" || !url, [url]);

    useImperativeHandle(ref, () => ({
      async saveLocalToCloud() {
        try {
          // image is empty, nothing to save
          if (currentSrcIsNotValid) {
            throw new Error("no message");
          }

          // image is the same, no need to save
          if (url === imgInBackend) {
            throw new Error("no message");
          }

          // Get new image out of storage and upload
          const fileFromSession = await fetch(url);

          // turn into blob
          const blob = await fileFromSession.blob();

          // File validieren und sanieren
          const cleandBlob = cleanFile(blob);

          // Überprüfen, ob die Datei gültig ist
          if (!cleandBlob) {
            throw new Error("Ungültige Datei nach der Sanierung.");
          }

          // Upload blob
          const res = await UploadToCloudService.upload(
            cleandBlob,
            typeOptions[type].resourceType
          );
          const { secure_url, duration } = res;

          const public_id = secure_url.split("/").pop().split(".")[0];
          const optimizedUrl = generateOptimizedUrl(
            public_id,
            typeOptions[type].resourceType
          );

          // Delete img in backend
          if (imgInBackend !== "" && !imgInBackend.startsWith("/")) {
            try {
              UploadToCloudService.delete(
                imgInBackend,
                typeOptions[type].resourceType
              );
            } catch (e) {
              console.log("Fehler beim löschen");
            }
          }

          // Set state
          setImageInBackend(optimizedUrl);
          setUrl(optimizedUrl);

          return {
            success: true,
            secure_url: optimizedUrl,
            duration: duration,
          };
        } catch (error) {
          if (error.message !== "no message") {
            console.log(error);
            showMessage("error", "Fehler beim Upload");
          }

          setUrl(imgInBackend);

          return { success: false, unchanged_secure_url: imgInBackend };
        }
      },
    }));

    const uploadToSession = async (e) => {
      const cleanedFile = cleanFile(e.target.files[0]);
      if (!cleanedFile) return;

      const key = Math.random * 100;

      try {
        showMessage("loading", "Bild wird geändert...", 0, key.toString());

        // Upload
        setUrl(URL.createObjectURL(cleanedFile));

        clearMessage(key.toString());
        showMessage("info", "Bild geändert!");
      } catch (error) {
        clearMessage(key.toString());
        showMessage("error", "Etwas ist schiefgelaufen");
      }
    };

    return (
      <label
        htmlFor={id}
        className={`relative block cursor-pointer ${typeOptions[type].wrapperClass}`}
      >
        {/**Upload */}
        <input
          type="file"
          accept={typeOptions[type].allowedFormats.join(", ")}
          id={id}
          className="absolute hidden"
          onChange={uploadToSession}
        />

        {currentSrcIsNotValid ? (
          <UploadBox text={textSrcNotThere} type={type} red={emptyCheck} />
        ) : (
          <BlueBox
            text={textSrcThere}
            src={url}
            blueCover={type === "avatar" ? false : true}
            type={type}
          />
        )}
      </label>
    );
  }
);

export default MediaUpload;

/**
 * Primitives
 */
const UploadBox = ({ text, type, red = true }) => {
  if (type === "image" || type === "video")
    return (
      <div
        className={`flex flex-col items-center justify-center h-full border-[0.5px] rounded-11xl border-dashed transition-all ${
          red
            ? "bg-red-200 bg-opacity-50 border-red-500"
            : "bg-transparent border-black"
        }`}
      >
        <img
          src="/images/icons-8-hochladen-1.png"
          alt="upload-icon"
          className="h-[66px] w-[66px]"
        />
        <p className="text-base font-semibold sm:text-xl">{text}</p>
        {red && (
          <p className="text-xs font-bold text-redattendtion sm:text-sm">
            (Sollte nicht leer sein)
          </p>
        )}
      </div>
    );

  if (type === "avatar")
    return (
      <img
        className="relative object-cover w-full h-full"
        src="/images/icons-8-mannlicher-benutzer-eingekreist-1.png"
        alt="Profilbild"
      ></img>
    );
};

const BlueBox = ({ text, src, blueCover, type }) => {
  return (
    <>
      {type === "image" && (
        <img
          className="absolute object-cover w-full h-full rounded-11xl"
          src={src}
        />
      )}

      {type === "avatar" && (
        <img className="absolute object-cover w-full h-full circle" src={src} />
      )}

      {type === "video" && (
        <video
          src={src}
          className="absolute object-cover w-full h-full rounded-11xl"
        >
          <source src={src} type="video/mp4" />
          <source src={src} type="video/webm" />
        </video>
      )}

      {blueCover && (
        <div className="absolute top-0 bottom-0 left-0 right-0 cover-upload-bg-second rounded-11xl">
          <div className="absolute flex flex-col items-center text-white bottom-4 right-4 xs:bottom-6 xs:right-6">
            <UploadIcon />
            <p className="text-base font-semibold sm:text-xl">{text}</p>
          </div>
        </div>
      )}
    </>
  );
};
