import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import LandingPageService from "../../service/LandingPageService";
const data = {
  finance: "Finanzberater",
  stormgas: "Strom & Gas",
  property: "Immobilienfinanzierung",
  individual: "Individual Landingpage",
};
const bgSeter = (bg) => {
  return {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "norepeat",
  };
};
const Index = () => {
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);
  return (
    <div className="max-w-[2000px] flex justify-center w-full m-auto">
      <div className="w-full">
        <div className="flex w-10/12 m-auto justify-between items-center my-10 ">
          <div className="xl:text-primary text-xl cursor-pointer text-[#868686]">
            <BsArrowLeft onClick={() => navigate(-1)} />
          </div>
          <div>
            <img
              src="/images/logo.png"
              alt=""
              className="sm:w-[50px] w-[30px]"
            />
          </div>
          <div></div>
        </div>
        <div className="text-9xl text-center font-semibold">
          <h1>Wähle hier zunächst eine für Dich passende Vorlage aus.</h1>
          <h1>Anschließend kannst Du im Konfigurator Anpassungen vornehmen.</h1>
        </div>
        <div className="sm:w-[80%] w-[95%] m-auto flex flex-col sm:gap-[50px] gap-3 my-10">
          {Object.keys(data).map((e, i) => (
            <div
              onClick={async () => {
                const { data } = await LandingPageService.create({
                  primary_image: user?.avatar,
                  theme: e,
                  user_id: user?._id,
                  background_image: `/landingPageBgs/${
                    e === "finance" ? e + ".svg" : e + ".png"
                  }`,
                });
                navigate(`/landingpageconfig/${e}/${data._id}`);
              }}
              style={bgSeter(`/images/card-bg-${i + 1}.png`)}
              className="overflow-hidden rounded-11xl cursor-pointer"
            >
              <div className="h-full backdrop-brightness-75  sm:p-10 p-5">
                <h1 className="sm:text-primary text-responsiveHeading text-white font-bold">
                  {data[e]}
                </h1>
                <p className="sm:text-9xl text-xl text-white font-semibold">
                  {" "}
                  Vorlage 01
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
