import { Switch } from "antd";
import React from "react";

const ToggleAndText = ({checked, onChange, text}) => {
  return (
    <div className="flex gap-[30px]">
      <div className="flex items-center justify-between">
        <div className="trkisswitch">
          <Switch
            checked={checked}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="text-base font-semibold lg:text-9xl sm: sm:text-xl">
        {text}
      </div>
    </div>
  );
};

export default ToggleAndText;
