import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingPageService from "../../../service/LandingPageService";

import LandingPageCard from "./LandingPageCard";
const Index = () => {
  const navigate = useNavigate();
  const [landingPages, setLandingPages] = useState([]);
  const getLandingPages = async () => {
    const { data } = await LandingPageService.list();

    setLandingPages(data.reverse());
  };
  useEffect(() => {
    getLandingPages();
  }, []);
  return (
    <div className="bg-[#fafafa] rounded-mini  p-4  sm:p-8 lg:!text-left  !text-center">
      <div className=" text-[28px] font-semibold my-10">Deine Landingpages</div>
      <div
        className="w-full flex justify-center items-center h-[200px]   rounded-mini bg-white box-border  overflow-hidden border-[1px] border-solid border-grey-accent mb-5"
        onClick={() => navigate("/landingpagenew")}
      >
        <div className="cursor-pointer flex flex-row items-start justify-start gap-[0.63rem]">
          <img
            className=" w-[1.69rem] h-[1.69rem] overflow-hidden shrink-0"
            alt=""
            src="/images/icons8plusmathe-1.png"
          />
          <div className=" font-semibold">Landingpage erstellen</div>
        </div>
      </div>
      <div className="  flex flex-col items-start justify-start gap-[1.88rem] text-[1.25rem]">
        {landingPages?.map((e, i) => (
          <LandingPageCard data={e} key={i} getLandingPages={getLandingPages} />
        ))}
      </div>
    </div>
  );
};

export default Index;
