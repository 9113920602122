import React from "react";
import { imageBackground } from "../../../../../utils/setImageBackground";
import Video from "../../shared/Video";
import WhatsappButton from "../../shared/WhatsappButton";

const HeroProperty = ({ landingPageData }) => {
  const {
    transformed_accompanyingTextOne,
    transformed_accompanyingTextTwo,
    transformed_accompanyingTextThree,
    transformed_introductoryText,
    transformed_whatsappText,
    whatsappNumber,
    background_image,
    logo,
    primary_image,
    whatsappButtonTextOne,
    persentationVideo,
    thumbnail,
    activateWhatsApp,
  } = landingPageData;
  return (
    <>
      {/** Desktop  */}
      <div className="hidden w-full px-32 py-12 h-[820px] secondary:flex">
        {logo && (
          <img
            src="/images/logo.png"
            alt="EinGuterTipp logo"
            className="absolute w-12 left-1/2 translate-x-[-50%] translate-y-[-50%] top-[48px]"
          />
        )}

        <div className="flex w-full h-full gap-12 max-w-[1600px] m-auto">
          <div className="flex flex-col justify-center w-[450px] gap-8">
            <p className="pb-20 text-4xl font-semibold">
              {transformed_introductoryText}
            </p>
            <p className="text-2xl font-bold">
              {transformed_accompanyingTextTwo}
            </p>
            <p className="text-2xl font-bold">
              {transformed_accompanyingTextThree}
            </p>
          </div>

          <div
            className="flex-1 rounded-sm bg-slate-300"
            style={{ ...imageBackground(background_image) }}
          />

          <div className="w-[450px]">
            <div
              className="h-[65%] rounded-sm"
              style={{ ...imageBackground(primary_image) }}
            ></div>
            <div className="pt-12 text-2xl font-bold">
              {transformed_accompanyingTextOne}
            </div>
          </div>
        </div>
      </div>
      {/** Mobile */}
      <div className="relative p-5 pt-16 text-center secondary:hidden">
        {logo && (
          <img
            src="/images/logo.png"
            alt="EinGuterTipp logo"
            className="absolute w-7 secondary:w-12 left-1/2 translate-x-[-50%] top-7"
          />
        )}
        <p className="text-2xl font-semibold pb-14">
          {transformed_introductoryText}
        </p>
        <div
          className="w-full h-[200px] md:h-[300px] rounded-sm mb-14 xs:w-[500px] primary:w-[600px] m-auto"
          style={{ ...imageBackground(background_image) }}
        ></div>
        <p className="text-xl font-semibold mb-14">
          {transformed_accompanyingTextOne}
        </p>

        {/** Video */}
        <Video
          url={persentationVideo}
          thumbnail={thumbnail}
          type="property"
          heading={landingPageData.transformed_introVideoHeader}
        />

        <WhatsappButton
          number={whatsappNumber}
          whatsappText={transformed_whatsappText}
          className="mt-14"
          text={whatsappButtonTextOne}
          activateWhatsApp={activateWhatsApp}
          type="property"
        />
      </div>
    </>
  );
};

export default HeroProperty;
