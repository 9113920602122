import { Alert, Button } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService.js";

const EmailConfirmInfo = ({ title }) => {
  useEffect(() => {
    title("Bestätigung");
  }, []);

  const loading = useSelector(selectLoading);

  return (
    <div>
      <Alert
        type="info"
        message="Schön, dass du dich registriert hast! Überprüfe nun Deine E-Mails, und klicke auf den Aktivierungslink um Deine E-Mail Adresse zu bestätigen."
      />
      <Button
        type="primary"
        className="mt-2"
        loading={loading}
        onClick={() => {
          if (loading) return;
          AuthService.confirmEmailSend();
        }}
      >
        Bestätigungsemail nochmal schicken
      </Button>
    </div>
  );
};

export default EmailConfirmInfo;
