import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP.js";
import { login } from "../../../redux/auth/actions.js";
import { store } from "../../../redux/store.js";
import AuthService from "../../../service/AuthService.js.js";
import User from "../../../service/UserService.js";
import { storeToken } from "../../../utils/LocalStorageHandler.js";
import FloatingLabel from "../../FloatingLabel/index";

const Index = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const [formData, setFormData] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.requestPasswordReset(formData);
      navigate("/resetpassword");
    } catch (error) {}
  };
  return (
    <div className="flex items-center justify-center py-[100px]">
      <div className="   w-full  text-center   items-center  px-10 flex flex-col justify-between">
        <MainLogo
          style={{
            transform: width < 500 ? "scale(2)" : "scale(2.5)",
            margin: "2rem auto",
          }}
        />
        <div>
          <h1 className="sm:text-primary text-9xl font-bold">
            Passwort vergessen
          </h1>
        </div>
        <form className="mt-20 w-full grid gap-[30px]" onSubmit={handleSubmit}>
          <FloatingLabel
            label={"E-Mail Adresse"}
            value={formData.email}
            unFocusClass="!top-[16px] !text-[16px]"
            className={"sm:my-[12px] m-0"}
          >
            <input
              required
              value={formData.email}
              name="email"
              type="email"
              onChange={handleChange}
              className="border-[1px]  text-[20px] font-semibold border-[#d6d6d6] w-full rounded-[10px] p-3 outline-none bg-transparent  "
            />
          </FloatingLabel>

          <button className="rounded-[15px] bg-darkslateblue text-white p-[12px_20px] text-center text-xl font-semibold">
            Abschicken
          </button>
        </form>
      </div>
    </div>
  );
};

export default Index;
