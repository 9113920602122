import { Alert, Button, Checkbox, message } from "antd";
import React, { useState } from "react";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import UserService from "../../service/UserService";
import CollapseItem from "../Dashboard/kampaign/CollapseItem";
import FloatLabel from "../FloatingLabel";
import { Pramienkeys, notiificationoconfig } from "./Data";

const Einstellungen = ({ title }) => {
  const getUser = async (setdata) => {
    const { data } = await UserService.me();
    store.dispatch(login(data));
    if (setdata) setUserSettings(data.user);
  };

  const [userSettings, setUserSettings] = useState({});
  React.useEffect(() => {
    title("Einstellungen");
    getUser(true);
    return () => {
      UserService.updateUserInfo({ ...userSettings });
    };
  }, [title]);
  const handleChange = (key, subkey, subsubkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev?.[key],
        [subkey]: { ...prev?.[key]?.[subkey], [subsubkey]: value },
      },
    }));
  };
  const handleChangePramien = (key, subkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [subkey]: value,
      },
    }));
  };

  const handleSave = async () => {
    await UserService.updateUserInfo({ ...userSettings }).then(() => {
      getUser(true);
      message.success("Erfolgreich gespeichert");
    });
  };
  return (
    <div>
      <CollapseItem
        defaultOpen={true}
        title={
          <div className="text-xl font-bold">Erinnerungen konfigurieren:</div>
        }
      >
        <div className="flex flex-col gap-7 mt-5 text-sm">
          {Object.keys(notiificationoconfig)?.map((e) => {
            const textParts = notiificationoconfig[e].split("...");

            return (
              <div className="flex  gap-5 items-center">
                <div className="flex gap-2 items-center">
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0 ">
                      App
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.app
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "app",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0">
                      Mail
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.email
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "email",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex  gap-5 items-center">
                  {textParts[0]}

                  {!!textParts[1] && (
                    <FloatLabel
                      className={
                        "border border-[#d8d8d8] rounded-3xs h-12 w-16  p-2"
                      }
                      keepitfocused
                      label={"Dauer"}
                    >
                      <input
                        type="text"
                        value={
                          userSettings?.notificationConfiguration?.[e]?.days
                        }
                        onChange={(ce) =>
                          handleChange(
                            "notificationConfiguration",
                            e,
                            "days",
                            ce.target.value,
                            ce.target.value.length > 0 ? /^[0-9]+$/g : null
                          )
                        }
                        className="outline-none border-none appearance-none h-full w-full bg-transparent"
                      />
                    </FloatLabel>
                  )}
                  {textParts[1] ?? ""}
                </div>
              </div>
            );
          })}
        </div>

        <Button
          className="h-[60px] w-full my-5 text-xl rounded-3xs"
          type="primary"
          onClick={() => handleSave(true)}
        >
          Speichern
        </Button>
      </CollapseItem>
      <div className="my-10">
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-xl font-semibold">
              Standardkonfiguration für Prämiensystem:
            </div>
          }
        >
          <div className="flex flex-col gap-7 mt-5 text-sm">
            <Alert
              type="info"
              message="Diese Einstellungen werden für jede neu angelegte Landingpage übernommen, können aber dort jederzeit angepasst werden."
            />
            {Object.keys(Pramienkeys)?.map((e, i) => {
              return (
                <FloatLabel
                  label={Pramienkeys[e]}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"xl:flex-grow-0 flex-grow"}
                  keepitfocused
                  key={i}
                >
                  <input
                    type="text"
                    className="border-[1px] border-[#d8d8d8] !shadow-none font-semibold !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                    value={
                      (userSettings?.pramienConfiguration?.[e] || 0) + " Euro"
                    }
                    onChange={(ce) =>
                      handleChangePramien(
                        "pramienConfiguration",
                        e,
                        ce.target.value.replace(" Euro", ""),
                        ce.target.value.replace(" Euro", "").length > 0
                          ? /^[0-9]+$/g
                          : null
                      )
                    }
                  />
                </FloatLabel>
              );
            })}
          </div>

          <Button
            className="h-[60px] w-full my-5 text-xl rounded-3xs"
            type="primary"
            onClick={() => handleSave(true)}
          >
            Speichern
          </Button>
        </CollapseItem>
      </div>
    </div>
  );
};

export default Einstellungen;
