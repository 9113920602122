import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LandingPageService from "../../../service/LandingPageService";
import { PopUpComponent } from "../../ConfirmPopup";

const LandingPageCard = ({ data, getLandingPages }) => {
  const [stats, setStats] = useState();
  useEffect(() => {
    const getLandingPages = async () => {
      const { data: statdata } = await LandingPageService.getStats(data?._id);

      setStats(statdata);
    };
    getLandingPages();
  }, [data]);
  const navigate = useNavigate();
  return (
    <div className="w-full gap-6 primary:flex-nowrap flex-wrap-reverse flex p-5 rounded-mini bg-white box-border border-[1px] border-solid border-grey-accent">
      <div className="w-full grid gap-3 ">
        <div className="font-semibold">{data?.name}</div>
        <div className="flex gap-3 xl:flex-nowrap flex-wrap items-center lg:justify-start justify-center">
          <div className=" sm:flex-nowrap flex-wrap  md:grid grid-cols-3 flex flex-row items-start justify-between secondary:w-[60%] tertiary:w-[40%] sm:gap-[2.5rem] gap-[10px] text-[0.88rem] text-text-sec">
            <div className="flex flex-col items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Erstellt Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">
                  {moment(data.createdAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className=" flex flex-col items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Geändert Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim w-full">
                <div className=" font-semibold">
                  {moment(data.updatedAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Aufrufe</div>
              <div className="flex flex-col items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">{stats?.clicks}</div>
              </div>
            </div>
          </div>

          <div className="md:w-auto w-full md:flex-nowrap font-semibold flex-wrap gap-[1.25rem]  flex flex-row items-center justify-center  text-[1rem] text-white">
            <div className=" m-auto rounded-3xs bg-[#001D47] cursor-pointer overflow-hidden flex  flex-col py-[0.91rem] px-[1.25rem] items-center justify-center">
              <Link to={`/landingpageconfig/${data?.theme}/` + data._id}>
                Konfigurieren
              </Link>
            </div>
            <div
              className=" m-auto rounded-3xs cursor-pointer bg-[#E81900] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center text-center"
              onClick={() =>
                PopUpComponent({
                  confirmButtonText: "Löschen",
                  onConfirm: async () => {
                    await LandingPageService.deleteLandingPage(data._id);
                    await getLandingPages();

                    try {
                    } catch (error) {}
                  },
                  heading: (
                    <>
                      Möchtest du deine Landingpage
                      <span className="mx-1 text-blue-main">
                        “{data.name}”
                      </span>{" "}
                      wirklich löschen?
                    </>
                  ),
                  subheading: (
                    <div className="text-red-500 font-bold">
                      Wenn du dich dazu entscheidest, diese Landingpage zu
                      löschen, werden auch alle zu dieser Seite gehörenden
                      Promolinks in den Promoterkarten gelöscht. Dieser Vorgang
                      ist endgültig und kann NICHT rückgängig gemacht werden!
                    </div>
                  ),
                })
              }
            >
              Löschen
            </div>
          </div>
        </div>
      </div>
      <div
        className="m-auto cursor-pointer transition-all image-overlay-eye-container relative w-full xl:w-[250px] h-[118px] overflow-hidden"
        onClick={() => {
          window.open(
            `/landingpage/${data?.theme}/${data._id}/viewmode`,
            "_blank"
          );
        }}
      >
        <img
          className="w-full   xl:w-[250px] h-[118px] rounded-xl   object-cover"
          alt=""
          src={data?.background_image}
        />
        <img
          className="animate-fade  image-overlay-eye inset-0  w-full aspect-[9/16]   xl:w-[250px]  h-[118px] bg-[rgba(255,255,255,0.4)] absolute rounded-xl  "
          alt=""
          src="/images/overlay-eye-img.svg"
        />
      </div>
    </div>
  );
};

export default LandingPageCard;
