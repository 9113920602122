import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class SubscriptionService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/subscription`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  subscribe(interval, subscriptionId, redirectUrl) {
    return this.api.post("/subscribe", {
      interval,
      subscription_id: subscriptionId,
      redirect: redirectUrl,
    });
  }

  cancelSubscription() {
    return this.api.delete("/cancel");
  }

  getSubscriptionInfo() {
    return this.api.get("/get");
  }
  generateSubscriptionManagementLink() {
    return this.api.post("/generateSubscriptionManagementLink", {
      baseUrl: window.location.origin,
    });
  }
}

export default new SubscriptionService();
