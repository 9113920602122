import { Button, Modal } from "antd";
import moment from "moment/moment";
import { useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import { LuPhone } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { PopUpComponent } from "../../ConfirmPopup";
const ExpandedCardVersionTwo = ({
  data,
  expanded,
  className,
  props,
  bg,
  manipulateTop,
  index,
  setList,
  deleteLead,
  paddingBottom,
}) => {
  const [SecondDropDown, setSecondDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [FirstDropDown, setFirstDropDown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [empfehlungZumPromoterMachen, setEmpfehlungZumPromoterMachen] =
    useState(false);
  const [newNote, setNewNote] = useState("");
  const [anredePromoter, setAnredePromoter] = useState("");
  const [emailPromoter, setEmailPromoter] = useState("");
  const notzRef = useRef();
  const questionRef = useRef();
  const cardExpandedRef = useRef();
  const navigate = useNavigate();
  const kontaktaufnahme = {
    recall: "Rückrufbitte",
    whatsapp: "Whatsapp",
    chooseDate: "Kalenderlink",
    manual: "Nachgetragen",
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setList({
      key: "notes",
      value: [
        ...data.notes,
        {
          text: newNote,
          timestamp: new Date(),
        },
      ],
    });
    setNewNote("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const colorSetter = () => {
    const colorClasses = {
      kunde: "text-green-500",
      "laufender-vorgang": "text-yellow-500",
      neu: "text-gray-500",
      "kein-interesse": "text-red-500",
      terminiert: "text-blue-500",
      "neuer-kunde-der-promoter-wurde": "text-purple-500",
    };

    return colorClasses[data.status];
  };

  return (
    <div
      className="w-full    absolute  pointer-events-none  transition-all  rounded-11xl duration-1000"
      {...props}
      onClick={(e) => {
        e.stopPropagation();

        props.onClick(() => {
          setFirstDropDown(true);
          setSecondDropDown(false);
          setTimeout(() => {
            manipulateTop(cardExpandedRef?.current?.offsetHeight * 0.8);
          }, 500);
        });
      }}
    >
      <Modal
        title="Kunden zum Promoter machen"
        open={empfehlungZumPromoterMachen}
        okText={"Speichern"}
        onOk={async () => {
          const result = await setList({
            key: "status",
            value: "neuer-kunde-der-promoter-wurde",
            anrede: anredePromoter,
            email: emailPromoter,
          });
          setEmpfehlungZumPromoterMachen(false);
          setAnredePromoter("");
          if (result?._id) {
            navigate(`/dashboard/promoter?promoter=${result._id}`);
          }
        }}
        onCancel={() => {
          setEmpfehlungZumPromoterMachen(false);
          setAnredePromoter("");
        }}
      >
        <label>Anrede</label>

        <select
          required={true}
          value={anredePromoter}
          onChange={(e) => setAnredePromoter(e.target.value)}
          prefixCls="promoterregselect"
          className="border-[1px]  shadow-none  w-full flex-grow   font-semibold border-[#d6d6d6] rounded-[10px] outline-none bg-transparent  "
        >
          <option value="Herr">Herr</option>
          <option value="Frau">Frau</option>
          <option value="Divers">Divers</option>
        </select>

        <label>Email</label>

        <input
          required={true}
          value={emailPromoter}
          onChange={(e) => setEmailPromoter(e.target.value)}
          prefixCls="promoterregselect"
          className="border-[1px]  shadow-none  w-full flex-grow   font-semibold border-[#d6d6d6] rounded-[10px] outline-none bg-transparent  "
        />
      </Modal>
      <Modal
        title="Neue Notiz"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
      </Modal>
      <div
        className={`  w-full relative top-[11%]  rounded-[30px] ${className}`}
      >
        <svg
          className="absolute right-0 w-[555px] md:block hidden top-[-100px] z-[17] pointer-events-none"
          height="102"
          viewBox="0 0 555 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M553.5 100.5H155.769C158.295 98.7366 160.335 96.2715 161.582 93.3094L187.705 31.2724C195.302 13.2314 212.97 1.5 232.545 1.5H525C540.74 1.5 553.5 14.2599 553.5 30V100.5Z"
            fill={bg[data.status][1]}
            stroke="#D8D8D8"
            strokeWidth="2"
          />
        </svg>
        <svg
          width="265"
          className="absolute right-[1px]  md:hidden  top-[-79px] z-[17] pointer-events-none"
          height="81"
          viewBox="0 0 265 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-85.2032 103C-85.2032 91.7977 -76.2489 82.7162 -65.2032 82.7162H-25.0156H-9.932C2.03887 82.7162 12.7016 75.0411 16.6527 63.5809L27.8826 31.0079C34.3272 12.3154 51.719 -0.203247 71.2445 -0.203247H244.797C255.843 -0.203247 264.797 8.87828 264.797 20.0805V71.2968C264.797 82.7162 264.797 81.7968 244.797 81.7968H-65.2032C-76.2489 81.7968 -85.2032 72.7152 -85.2032 61.5129V103Z"
            fill={bg[data.status][1]}
            stroke="#D8D8D8"
            strokeWidth="2"
          />
        </svg>

        <div
          className="absolute md:right-0 right-[-100px] top-[-80px]  md:top-[-100px] w-[386px] pointer-events-auto h-[100px] z-[17]"
          onClick={() => props.onClick()}
        >
          <div className="text-center h-full flex  items-center ps-[4rem]">
            {" "}
            <h1
              className={classNames(
                `leading-[38px] md:w-[300px] w-[200px] whitespace-normal hyphens-auto  text-white font-medium `,
                {
                  "text-xl  md:text-[28px]": expanded,
                  "text-base md:text-[20px]": !expanded,
                }
              )}
              style={
                bg[data.status]?.[0] === "#FFE465" ? { color: "black" } : {}
              }
            >
              {data?.firstname}
              {"  "} {data?.lastname}
            </h1>
          </div>
        </div>
        <div
          className={`z-50 relative  rounded-[30px_0px_30px_30px] border-editor border-[2px] border-[#D8D8D8] pointer-events-auto antialiased `}
          style={{
            background: `linear-gradient(${
              index === 3 ? "530deg" : "535deg"
            }, ${bg[data.status][0]} 0%,${bg[data.status][1]} 16.06%,${
              bg[data.status][2]
            } 100%)`,
            boxShadow: `2px 33px 18px -1px ${bg[data.status][1]} inset
            `,
          }}
        >
          <div className="specialeditor" style={{ color: bg[data.status][1] }}>
            <svg
              width="52"
              height="65"
              viewBox="0 0 52 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M270.5 6V1681C270.5 1691.22 262.217 1699.5 252 1699.5H-58C-68.2173 1699.5 -76.5 1691.22 -76.5 1681V82.6154C-76.5 72.3981 -68.2173 64.1154 -58 64.1154H-17.8124H-1.61235C10.3614 64.1154 21.0754 56.6776 25.2613 45.4593L35.8546 17.0688C42.4889 -0.711495 59.47 -12.5 78.4476 -12.5H252C262.217 -12.5 270.5 -4.21727 270.5 6Z"
                fill="currentColor"
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="desktopspecialeditor">
            <svg
              width="76"
              height="81"
              viewBox="0 0 76 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M423.5 16V413C423.5 428.74 410.74 441.5 395 441.5H-655C-670.74 441.5 -683.5 428.74 -683.5 413V107.943C-683.5 92.2026 -670.74 79.4427 -655 79.4427H-130H-9.52728H17.07C23.2811 79.4427 28.9303 75.8464 31.559 70.2191L56.5106 16.8054C64.8627 -1.07384 82.8116 -12.5 102.545 -12.5H395C410.74 -12.5 423.5 0.259888 423.5 16Z"
                fill={bg[data.status][1]}
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>
          <div
            className="super-line"
            style={{
              background: bg[data.status][1],
              boxShadow: `${bg[data.status][1]} 2px 28px 18px -1px,${
                bg[data.status][1]
              } 0px -2px 21px -2px`,
            }}
          ></div>
          <div className="super-special-div p-6 rounded-[30px_0px_30px_30px]">
            <div
              className={`z-50 relative p-3  transition-all duration-1000 rounded-[15px] pointer-events-auto `}
              style={{
                minHeight: "100px",
                overflow: "hidden",
                maxHeight: expanded ? "1500px" : "100px",
              }}
            >
              {expanded ? (
                <div style={{ paddingBottom }} ref={cardExpandedRef}>
                  <div className="white-text-div-folder bg-white p-3 px-[2rem] gap-5 flex flex-wrap justify-between rounded-[15px]">
                    <div>
                      <p>Name</p>
                      <h5>
                        {" "}
                        {data?.firstname}
                        {"  "}
                      </h5>
                    </div>
                    <div>
                      <p>Telefon</p>
                      <h5>
                        <a href={`tel:${data.phone}`}>{data.phone}</a>
                      </h5>
                    </div>
                    {/* <div>
                      <p>E-Mail der Empfehlung</p>
                      <h5>
                        <a href={`mailto:${data.email}`}>{data.email}</a>
                      </h5>
                    </div> */}
                    <div>
                      <p>geworben von </p>
                      <h5>{`${data?.promoter_id?.firstname ?? ""} ${
                        data?.promoter_id?.lastname ?? ""
                      }`}</h5>
                    </div>
                    <div>
                      <p>Eingang am</p>
                      <h5>{moment(data.createdAt).format("DD.MM.YYYY")}</h5>
                    </div>
                    <div>
                      <p>Zuletzt bearbeitet</p>
                      <h5>{moment(data.updatedAt).format("DD.MM.YYYY")}</h5>
                    </div>
                    <div>
                      <p>Kontaktaufnahme</p>
                      <h5>{kontaktaufnahme[data.type]}</h5>
                    </div>
                    <div>
                      <p>Status</p>
                      {data.status?.includes?.("promoter") ? (
                        <div className="text-purple-500">Promoter</div>
                      ) : (
                        <div className="no-arr-select">
                          <select
                            className={`outline-none ${colorSetter()}`}
                            onChange={(e) => {
                              if (e.target.value === "kein-interesse") {
                                PopUpComponent({
                                  onConfirm: () => {
                                    setList({
                                      key: "status",
                                      value: "kein-interesse",
                                      istKontaktGesprach: true,
                                    });
                                  },
                                  onCancel: () => {
                                    setList({
                                      key: "status",
                                      value: "kein-interesse",
                                      istKontaktGesprach: false,
                                    });
                                  },
                                  heading: "Ablehnung kategorisieren",
                                  subheading:
                                    "Damit wir Dir zielgenaue Statistiken anzeigen können, muss diese Ablehnung kategorisiert werden. Falls es zu einem Kontaktgespräch kam, und die Empfehlung deine Annäherung explizit abgelehnt hat, so wähle bitte die Option 'Ablehnung'. Falls Du die Empfehlung nicht erreichen konntest, dann wähle die Option 'Nicht Erreichbar'",
                                  confirmButtonText: "Ablehnung",
                                  cancelButtonText: "Nicht Erreichbar",
                                });
                              } else if (
                                ["terminiert", "neuer-kunde", "kunde"].includes(
                                  e.target.value
                                )
                              ) {
                                const newValue = e.target.value;
                                PopUpComponent({
                                  onConfirm: () => {},
                                  onCancel: async () => {
                                    if (loading) return;
                                    setLoading(true);
                                    try {
                                      await setList({
                                        key: "status",
                                        value: newValue,
                                      });
                                    } catch (e) {
                                    } finally {
                                      setLoading(false);
                                    }
                                  },
                                  heading: "Bist du dir sicher?",
                                  subheading:
                                    "Du kannst diese Aktion nicht mehr rückgängig machen.",
                                  confirmButtonText: "Abbrechen",
                                  cancelButtonText: "Ja, bin ich",
                                });
                              } else {
                                setList({
                                  key: "status",
                                  value: e.target.value,
                                });
                              }
                            }}
                            value={data.status}
                          >
                            {![
                              "kein-interesse",
                              "neuer-kunde",
                              "neuer-kunde-der-promoter-wurde",
                              "kunde",
                              "terminiert",
                            ].includes(data.status) && (
                              <>
                                <option
                                  className="text-yellow-500"
                                  value={"laufender-vorgang"}
                                >
                                  offener Vorgang
                                </option>
                                <option className="text-gray" value={"neu"}>
                                  neue Empfehlung
                                </option>
                              </>
                            )}
                            {![
                              "neuer-kunde",
                              "neuer-kunde-der-promoter-wurde",
                              "kunde",
                            ].includes(data.status) && (
                              <>
                                <option
                                  className="text-red-500"
                                  value={"kein-interesse"}
                                >
                                  kein Interesse
                                </option>
                              </>
                            )}

                            {![
                              "kein-interesse",
                              "neuer-kunde-der-promoter-wurde",
                            ].includes(data.status) && (
                              <option
                                className="text-green-500"
                                value={"kunde"}
                              >
                                neuer Kunde
                              </option>
                            )}
                            {![
                              "kein-interesse",
                              "neuer-kunde",
                              "neuer-kunde-der-promoter-wurde",
                              "kunde",
                            ].includes(data.status) && (
                              <option
                                className="text-blue-500"
                                value={"terminiert"}
                              >
                                terminiert
                              </option>
                            )}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      className="flex items-center gap-3 text-white mt-10 transition-all "
                      style={
                        bg[data.status]?.[0] === "#FFE465"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          setFirstDropDown(!FirstDropDown);
                          if (FirstDropDown) {
                            manipulateTop(
                              (prev) => prev - notzRef.current.offsetHeight
                            );
                          } else {
                            setTimeout(() => {
                              manipulateTop(
                                (prev) => prev + notzRef.current.offsetHeight
                              );
                            }, 500);
                          }
                        }}
                      >
                        Notizen
                      </p>
                      <IoIosArrowDown
                        className="transition-all cursor-pointer"
                        onClick={() => {
                          setFirstDropDown(!FirstDropDown);
                          if (FirstDropDown) {
                            manipulateTop(
                              (prev) => prev - notzRef.current.offsetHeight
                            );
                          } else {
                            setTimeout(() => {
                              manipulateTop(
                                (prev) => prev + notzRef.current.offsetHeight
                              );
                            }, 500);
                          }
                        }}
                        style={{
                          rotate: !FirstDropDown ? "180deg" : "0deg",
                        }}
                      />
                    </div>

                    <div
                      className=" !transition-all"
                      style={{
                        display: FirstDropDown ? "block" : "none",

                        // overflow: FirstDropDown ? "visible" : "hidden",
                      }}
                      ref={notzRef}
                    >
                      <Button
                        type="primary"
                        className="bg-[#001D47] my-3"
                        onClick={showModal}
                      >
                        Neue Notiz
                      </Button>
                      {/* {
          text: String,
          timestamp: Date,
          9. Juli 2023 - 16:20
        } */}

                      <div className="flex flex-col gap-3 overflow-auto max-h-[200px] pt-3">
                        {data?.notes?.length > 0 ? (
                          [
                            ...data?.notes?.sort((a, b) => {
                              const date1 = new Date(a?.timestamp);
                              const date2 = new Date(b?.timestamp);

                              return date2 - date1;
                            }),
                          ]?.map((e, i) => (
                            <div
                              key={i}
                              className="  bg-white py-[1rem] w-[97%] px-[2rem] rounded-[15px] relative"
                            >
                              <div>
                                <p className=" text-[12px] text-[#868686]">
                                  {moment(e?.timestamp).format(
                                    "DD. MMMM YYYY - h:mm"
                                  )}
                                </p>
                                <p>{e?.text}</p>
                                <div className="bg-white border border-[#d8d8d8]  p-2 absolute top-[-10px] right-[-10px] rounded-[8px] w-fit">
                                  <RxCross1
                                    className="cursor-pointer"
                                    onClick={() => {
                                      PopUpComponent({
                                        onConfirm: () => {
                                          setList({
                                            key: "notes",
                                            value: [...data.notes].filter(
                                              (fe) => fe._id !== e._id
                                            ),
                                          });
                                        },
                                        heading:
                                          "Bist Du dir sicher, dass du diese Notiz löschen möchtest?",
                                        confirmButtonText: "Löschen",
                                        cancelButtonText: "Abbrechen",
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-white"
                            style={
                              bg[data.status]?.[0] === "#FFE465"
                                ? { color: "black" }
                                : {}
                            }
                          >
                            Noch keine Notizen
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center flex-wrap gap-3 my-10">
                      {data.status === "kunde" && (
                        <Button
                          className="bg-[#001D47] text-white  "
                          type="primary"
                          onClick={() =>
                            PopUpComponent({
                              onCancel: () => {
                                setEmpfehlungZumPromoterMachen(true);
                              },
                              heading: "Kunden zum Promoter machen",
                              subheading:
                                "Bist du dir sicher? Du kannst diese Aktion nicht mehr rückgängig machen.",
                              confirmButtonText: "Abbrechen",
                              cancelButtonText: "Ja, bin ich",
                            })
                          }
                        >
                          Diesen Kunden zum Promoter machen
                        </Button>
                      )}

                      <Button
                        className="bg-[#E81900] !text-white   border-none"
                        onClick={async () =>
                          PopUpComponent({
                            onConfirm: () => {
                              deleteLead();
                            },
                            heading: "Empfehlung Löschen",
                            subheading: `Bist du dir sicher, dass du die Empfehlung ${data?.firstname} ${data?.lastname} löschen möchtest?`,
                            confirmButtonText: "Okay",
                            cancelButtonText: "Abbrechen",
                          })
                        }
                      >
                        Diese Empfehlung löschen
                      </Button>
                    </div>
                    <div
                      className="transition-all flex items-center my-10 text-white gap-3  relative z-100"
                      style={
                        bg[data.status]?.[0] === "#FFE465"
                          ? { color: "black" }
                          : {}
                      }
                    >
                      <p
                        className="cursor-pointer"
                        style={
                          bg[data.status]?.[0] === "#FFE465"
                            ? { color: "black" }
                            : {}
                        }
                        onClick={() => {
                          setSecondDropDown(!SecondDropDown);
                          if (SecondDropDown) {
                            manipulateTop(
                              (prev) => prev - questionRef.current.offsetHeight
                            );
                          } else {
                            setTimeout(() => {
                              manipulateTop(
                                (prev) =>
                                  prev + questionRef.current.offsetHeight
                              );
                            }, 500);
                          }
                        }}
                      >
                        Antworten auf Fragen
                      </p>
                      <IoIosArrowDown
                        className="transition-all"
                        onClick={() => {
                          setSecondDropDown(!SecondDropDown);
                          if (SecondDropDown) {
                            manipulateTop(
                              (prev) => prev - questionRef.current.offsetHeight
                            );
                          } else {
                            setTimeout(() => {
                              manipulateTop(
                                (prev) =>
                                  prev + questionRef.current.offsetHeight
                              );
                            }, 500);
                          }
                        }}
                        style={{
                          rotate: !SecondDropDown ? "180deg" : "0deg",
                        }}
                      />
                    </div>
                    <div
                      className=" !transition-all"
                      style={{
                        display: SecondDropDown ? "block" : "none",
                      }}
                    >
                      <div
                        className="flex md:flex-row flex-col gap-6 !transition-all"
                        ref={questionRef}
                      >
                        {data.answersToQuestions.length > 0 ? (
                          data.answersToQuestions.map((qe, i) => (
                            <div
                              className="bg-white rounded-[15px] text-center p-1 !transition-all flex-grow my-2"
                              key={i}
                            >
                              <p className="font-semibold">
                                {qe?.question_text}
                              </p>
                              <div className="my-2 text-center">
                                {qe?.answers
                                  ?.filter(
                                    (e) => qe?.selectedAnswer === e?.text
                                  )
                                  .map((e, index) => (
                                    <p className="text-[#868686] " key={index}>
                                      {typeof e === "object" ? (
                                        <p
                                          className={classNames({
                                            "text-blue-500 font-bold":
                                              qe?.selectedAnswer === e?.text,
                                          })}
                                        >
                                          {e.text}
                                        </p>
                                      ) : (
                                        e
                                      )}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div
                            className="text-white"
                            style={
                              bg[data.status]?.[0] === "#FFE465"
                                ? { color: "black" }
                                : {}
                            }
                          >
                            Noch keine Frage-Antwort
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="h-[50px] relative"
                  style={{
                    zIndex: (props.style.zIndex + 1) * 1,
                  }}
                >
                  <div className="md:flex hidden items-center gap-3">
                    <p
                      className={classNames("text-sm", {
                        "text-white": bg[data.status]?.[0] !== "#FFE465",
                      })}
                    >
                      Telefon
                    </p>
                    <h5
                      className={classNames("font-semibold", {
                        "text-white": bg[data.status]?.[0] !== "#FFE465",
                      })}
                    >
                      <a href={`tel:${data.phone}`}>{data.phone}</a>
                    </h5>
                  </div>

                  <a
                    href={`tel:${data.phone}`}
                    className="text-white md:hidden text-primary"
                  >
                    <LuPhone />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export { ExpandedCardVersionTwo };
