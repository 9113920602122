import { Alert, Button, Skeleton, Tooltip } from "antd";
import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import Feature from "./Feature";
import EChart from "./LineChart.jsx";
import {
  BlueDownArrow,
  Folder,
  GoldEuro,
  GreenHeart,
  Head,
  Heart,
  Mouse,
  New,
  People,
  PeopleBlue,
  StopWatch,
  Warning,
} from "./Svgs";

import moment from "moment/moment";
import { useCallback, useEffect, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import { setCACHE } from "../../../redux/cache/actions";
import { selectCache } from "../../../redux/cache/selectors";
import { store } from "../../../redux/store";
import NotificationService from "../../../service/NotificationService";
import UserService from "../../../service/UserService";
import { GreenTicketFill, RejectedIcon } from "../../AdminDashboard/Home/Svgs";
import { ICONSELECTOR } from "../../Notification/index.jsx";
import { Filter } from "../Promoter/Svgs.js";

const Index = ({ resultFilter }) => {
  const { user } = useSelector(selectUser);
  const validateBillingAddresses = () => {
    const warningText = [];
    const fields = {
      street: "Straße",
      streetNr: "Hausnummer",
      city: "Ort",
      postCode: "PLZ",
      phone: "Telefonnummer",
      companyName: "Firma",
      industry: "Branche",
      dateOfBirth: "Geburtsdatum",
      VAT: "Umsatzsteuernummer / Steuernummer",
    };
    // firstname, lastname, firma, umsatzsteuer, straße, hausnummer, ort, plz, branche, telefonnummer
    Object.keys(fields).forEach((e, i) => {
      if (!user[e] || user[e]?.length === 0) {
        warningText.push(fields[e]);
      }
    });
    return warningText?.join(", ");
  };
  const cache = useSelector(selectCache);
  const [loading, setLoading] = useState(true);
  const [navigating, setNavigating] = useState(false);

  const [stats, setStats] = useState({
    cards: {
      aufrufe: "0",
      aufrufeProPromoter: "0",
      eingelostePramien: "0",
      empfehlungen: "0",
      kundenOhnePromoter: "0",
      neueEmpfehlungen: "0",
      offeneVorgange: "0",
      promoter: "0",
      promoterOhneAufrufe: "0",
      promoterOhneEinwilligung: "0",
    },
    performanceFormula: {
      aufrufe: 0,
      empfehlung: 0,
      kontaktgesprache: 0,
      neuerPromoter: 0,
      neukunde: 0,
      termine: 0,
    },
  });
  const [notification, setNotifications] = useState([]);
  const [pramienNotification, setPramienNotification] = useState([]);
  const getDashboardStats = useCallback(async () => {
    try {
      NotificationService.getInboundMessages({ limit: 100, page: 0 })
        .then(({ data }) => {
          setNotifications(data);
        })
        .catch((e) => console.log(e));

      const { data } = await UserService.getDashboardStats(resultFilter);

      const { data: perfomanceFomulaeData } =
        await UserService.getLeistungsFormelStats(resultFilter);

      for (let values of Object.keys(data)) {
        data[values] = data[values].split(".")[0];
      }
      for (let values of Object.keys(perfomanceFomulaeData)) {
        perfomanceFomulaeData[values] = String(
          perfomanceFomulaeData[values].toFixed(1)
        ).replace(".", ",");
      }
      setStats((prev) => {
        const stats = {
          ...prev,
          cards: { ...prev.cards, ...data },
          performanceFormula: {
            ...prev.performanceFormula,
            ...perfomanceFomulaeData,
          },
        };
        store.dispatch(setCACHE({ [resultFilter]: stats }));
        return stats;
      });
    } catch (error) {
      console.log(error, cache);
    }
  }, [resultFilter]);
  const navigate = useNavigate();

  const getNotification = async () => {
    setLoading(true);
    const { data: notification } = await NotificationService.getInboundMessages(
      { pages: 0, limit: 10 }
    );
    setNotifications(notification);
    setLoading(false);

    const { data } = await NotificationService.getPramienNotification();
    if (data) setPramienNotification(data);
  };

  useEffect(() => {
    getDashboardStats();
  }, [getDashboardStats]);
  useEffect(() => {
    getNotification();
  }, []);

  const getNotificationLink = (e) => {
    const promoter_id =
      e?.data?.promoter_id ||
      (e?.type?.includes?.("promoter") ? e?.targetId : null);
    const empfehlung_id =
      e?.data?.empfehlung_id ||
      (e?.type?.includes?.("empfehlung") ? e?.targetId : null);

    if (promoter_id) return `/dashboard/promoter?promoter=${promoter_id}`;
    if (empfehlung_id)
      return `/dashboard/empfehlungen?empfehlungen=${empfehlung_id}`;
    return null;
  };

  return (
    <div className="pb-10 sm:p-0 px-2">
      <div
        className="flex secondary:h-[400px] h-full secondary:flex-nowrap flex-wrap justify-between gap-10 "
        style={{ width: "auto" }}
      >
        <div className="secondary:w-[calc(100%-500px)] h-full flex flex-col gap-10 w-full p-5 bg-[#fafafa] rounded-[30px] ">
          <h1 className="font-semibold text-xl  sm:text-9xl">
            Deine Zahlen im Überblick.
          </h1>
          <div
            className="overflow-x-scroll flex h-full py-5 items-center sm:gap-4 gap-1
           "
            style={{ overflowY: "hidden" }}
          >
            <div
              onClick={async () => {
                setNavigating("/dashboard/empfehlungen");
                setTimeout(() => navigate("/dashboard/empfehlungen"), 1000);
              }}
              style={
                navigating === "/dashboard/empfehlungen"
                  ? {
                      background: "rgb(189, 198, 190)",
                      color: "white",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {stats.cards?.neueEmpfehlungen}
              </span>
              <p
                className="font-[500] text-[14px] text-[#868686]"
                style={
                  navigating === "/dashboard/empfehlungen"
                    ? {
                        color: "white",
                      }
                    : {}
                }
              >
                Neue Empfehlungen
              </p>
              <p className="flex justify-center items-center">
                <New />
              </p>
            </div>
            <div
              onClick={async () => {
                setNavigating("/dashboard/landingpage");
                setTimeout(() => navigate("/dashboard/landingpage"), 1000);
              }}
              style={
                navigating === "/dashboard/landingpage"
                  ? {
                      border: "3px solid black",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3 flex-1  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="red-mark-upper text-xl justify-center  font-semibold flex items-center">
                {stats.cards?.aufrufe}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">Aufrufe</p>
              <p className="flex justify-center items-center">
                <Mouse />
              </p>
            </div>
            <div
              onClick={async () => {
                setNavigating("/dashboard/landingpage2");
                setTimeout(() => navigate("/dashboard/landingpage"), 1000);
              }}
              style={
                navigating === "/dashboard/landingpage2"
                  ? {
                      border: "3px solid black",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="red-mark-upper text-xl justify-center  font-semibold flex items-center">
                {stats.cards.aufrufeProPromoter}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Ø Aufrufe pro Promoter
              </p>
              <p className="flex justify-center items-center">
                <People />
              </p>
            </div>
            <div
              onClick={async () => {
                setNavigating("/dashboard/empfehlungen2");
                setTimeout(() => navigate("/dashboard/empfehlungen"), 1000);
              }}
              style={
                navigating === "/dashboard/empfehlungen2"
                  ? {
                      background: "rgb(189, 198, 190)",
                      color: "white",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="green-mark-upper text-xl justify-center  font-semibold flex items-center">
                {stats.cards.empfehlungen}
              </span>
              <p
                className="font-[500] text-[14px] text-[#868686]"
                style={
                  navigating === "/dashboard/empfehlungen2"
                    ? {
                        color: "white",
                      }
                    : {}
                }
              >
                Empfehlungen
              </p>
              <p className="flex justify-center items-center">
                <Heart />
              </p>
            </div>
            <div
              onClick={async () => {
                setNavigating("/dashboard/promoter");
                setTimeout(() => navigate("/dashboard/promoter"), 1000);
              }}
              style={
                navigating === "/dashboard/promoter"
                  ? {
                      background: "rgb(148, 83, 211)",
                      color: "white",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {stats.cards.promoter}
              </span>
              <p
                className="font-[500] text-[14px] text-[#868686]"
                style={
                  navigating === "/dashboard/promoter"
                    ? {
                        color: "white",
                      }
                    : {}
                }
              >
                Promoter
              </p>
              <p className="flex justify-center items-center">
                <New />
              </p>
            </div>
            <div
              onClick={async () => {
                setNavigating("/dashboard/prämien");
                setTimeout(() => navigate("/dashboard/prämien"), 1000);
              }}
              style={
                navigating === "/dashboard/prämien"
                  ? {
                      border: "3px solid black",
                    }
                  : {}
              }
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
            >
              <span className="green-mark-upper text-xl w-fit m-auto font-semibold ">
                {stats.cards.eingelostePramien}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Eingelöste Prämien
              </p>
              <p className="flex justify-center items-center">
                <New />
              </p>
            </div>
          </div>
          <div className="flex items-center  flex-wrap gap-10">
            <div className="flex items-center text-[#868686] gap-4">
              <Filter />
              <p>
                Filtere direkt nach Personen, indem du die Status-Karte
                anklickst.
              </p>
            </div>
          </div>
        </div>
        <div
          className="secondary:w-[400px] relative h-[400px] w-full overflow-auto  rounded-[30px] p-[10px] bg-[#fafafa] flex flex-col items-center  gap-2"
          style={{ width: "auto", flexGrow: "100" }}
        >
          {!(
            user?.defaultImpressumLink?.length > 0 ||
            user?.defaultImpressumText?.length > 0
          ) && (
            <Link
              to={"/dashboard/profile"}
              className="w-full flex bg-white rounded-[20px] items-center p-1 pl-3"
              style={{ border: "1px solid red" }}
            >
              <div>
                <ICONSELECTOR type={"warning"} />
              </div>
              <div className="p-[10px]">
                <h1 className="text-[12px] text-black">Impressum fehlt</h1>
                <p className="text-[#868686]">
                  Um Promotionslinks zu erstellen, musst du dein Impressum
                  aktualisieren
                </p>
              </div>
            </Link>
          )}
          {validateBillingAddresses()?.length > 0 && (
            <Link
              to={"/dashboard/profile"}
              className="w-full flex bg-white rounded-[20px] items-center p-1 pl-3"
              style={{ border: "1px solid red" }}
            >
              <div>
                <ICONSELECTOR type={"warning"} />
              </div>
              <div className="p-[10px]">
                <h1 className="text-[12px] text-black">Daten fehlen</h1>
                <p className="text-[#868686] ">
                  {validateBillingAddresses()} fehlt
                </p>
              </div>
            </Link>
          )}
          {loading ? (
            <Skeleton active />
          ) : notification?.messages?.length > 0 ? (
            notification?.messages
              .filter((e) => e.type !== "pramien")
              .map((e, i) => (
                <Link
                  to={getNotificationLink(e)}
                  className="w-full flex bg-white rounded-[20px] items-center p-1 pl-3"
                  key={i}
                >
                  <div>
                    <ICONSELECTOR type={e.type} />
                  </div>
                  <div className="p-[10px]">
                    <h1 className="text-[12px] text-black">{e.text}</h1>
                    <p className="text-[#868686]">
                      {moment(e.createdAt).fromNow()}
                    </p>
                  </div>
                </Link>
              ))
          ) : (
            <div className="text-black text-xl">
              Leg los um Benachrichtigen zu erhalten.
            </div>
          )}
        </div>
      </div>
      <div
        className="secondary:h-[400px] secondary:flex-nowrap flex-wrap h-full my-10 justify-between flex gap-10"
        style={{ width: "auto" }}
      >
        <div
          className="secondary:w-[400px] w-full bg-glass-circles  sm:rounded-[30px] rounded-[20px]  overflow-hidden p-3"
          style={{ width: "auto", flexGrow: "100" }}
        >
          <div className="text-center font-semibold text-white">
            <p className=" primary:text-[28px]  text-xl">Prämienfeed</p>
            <p className="primary:text-[16px] text-[14px]">
              {" "}
              Einlösungen & Anforderungen
            </p>
          </div>
          <div className="flex flex-col h-[300px] gap-1 grid-cols-1 p-2 overflow-y-auto rounded-[30px]">
            {pramienNotification.map((e, i) => (
              <Link
                to="/dashboard/prämien"
                className="rounded-[20px] p-[10px] bg-[#ffffffbb] flex cursor-pointer"
                key={i}
              >
                <div>
                  <GoldEuro />
                </div>
                <div>
                  <p className="">{e.text}</p>
                  <p className="text-[#868686]">
                    {" "}
                    {moment(e.createdAt).fromNow()}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="secondary:w-[calc(100%-500px)] w-full bg-[#FAFAFA] rounded-[30px] sm:p-5 ">
          <div className="sm:p-5  w-full">
            {" "}
            <div className="flex items-center justify-between ">
              <h1 className="font-semibold  sm:text-9xl text-xl">
                Deine Leistungsformel
              </h1>
              <Tooltip title="Die Leistungsformel zeigt Dir auf Basis Deiner persönlichen Kennzahlen, was Du für einen Neukunden benötigst. Neben Trends erkennst Du sehr schnell an welchen Stellen es Probleme gibt und wo Du nachbessern musst. Die Genauigkeit Deiner Leistungsformel wächst mit der Zeit. Erreiche 10 Neukunden um Deine Leistungsformel zu aktivieren.">
                <Button
                  type="default"
                  className="w-[40px] aspect-square h-[40px] p-0 flex justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8]"
                >
                  <AiOutlineInfoCircle fontSize={20} />
                </Button>
              </Tooltip>
            </div>
            <div className="py-5">
              <div className="grid primary:grid-cols-3 grid-cols-1  primary:grid-rows-2 rounded-[15px] bg-white">
                <div className="border-[1px]  border-collapse border-[#d8d8d8] rounded-tl-[15px]  primary:rounded-tr-[0] rounded-tr-[15px] relative       primary:order-1 order-1">
                  <div className="w-full text-center  relative  my-7">
                    <div>
                      <h1 className="text-xl justify-center w-full  font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl green-mark">
                          {stats.performanceFormula.aufrufe}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[#868686]">
                        Aufrufe
                      </p>
                    </div>
                    <IoIosArrowForward
                      width={35}
                      height={35}
                      color="#D8D8D8"
                      className="absolute -right-3 top-[13px] bottom-0 primary:block hidden primary:text-primary text-xl"
                    />
                  </div>
                  <IoIosArrowDown
                    width={35}
                    height={35}
                    color="#D8D8D8"
                    className="absolute right-0 left-0 -bottom-1 m-auto primary:text-primary text-xl primary:hidden"
                  />
                </div>
                <div className="primary:border-y-[1px] relative border-[1px]  border-collapse border-[#d8d8d8] primary:rounded-tr-none rounded-[0] primary:order-1 order-1">
                  <div className="w-full text-center  relative  my-7">
                    <div>
                      <h1 className="text-xl justify-center w-full  font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl green-mark">
                          {stats.performanceFormula.empfehlung}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[#868686]">
                        Empfehlungen
                      </p>
                    </div>
                    <IoIosArrowForward
                      width={35}
                      height={35}
                      color="#D8D8D8"
                      className="absolute -right-3 top-[13px] bottom-0 primary:text-primary text-xl primary:block hidden"
                    />
                  </div>
                  <IoIosArrowDown
                    width={35}
                    height={35}
                    color="#D8D8D8"
                    className="absolute right-0 left-0 -bottom-1 m-auto primary:text-primary text-xl primary:hidden"
                  />
                </div>
                <div className="border-[1px]  border-collapse border-[#d8d8d8] relative   primary:rounded-tr-[15px] rounded-[0] primary:order-1 order-2">
                  <div className="w-full text-center justify-between  relative  my-7 flex items-center">
                    <div className="w-[35px]"></div>
                    <div>
                      <h1 className="text-xl justify-center w-full  font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl red-mark">
                          {stats.performanceFormula.kontaktgesprache}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[#868686] hyphens-manual">
                        Kontakt&shy;gespräche
                      </p>
                    </div>
                    <div className="w-[35px]"></div>
                  </div>
                  <IoIosArrowDown
                    width={35}
                    height={35}
                    color="#D8D8D8"
                    className="absolute right-0 left-0 m-auto  primary:-bottom-3 -bottom-1 primary:text-primary text-xl "
                  />
                </div>
                <div className="border-[1px]  border-t-[0px] relative   border-collapse border-[#d8d8d8]  rounded-bl-[15px]   primary:rounded-br-[0px] rounded-br-[15px]   primary:order-1 order-8">
                  <div className="flex items-center w-full text-center justify-between  my-7">
                    <div className="w-[35px] "></div>

                    <span>
                      <h1 className="text-xl justify-center w-full  font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl green-mark">
                          {stats.performanceFormula.neuerPromoter}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[#868686]">
                        neue Promoter
                      </p>
                    </span>

                    <div className="w-[35px] "></div>
                  </div>
                </div>
                <div className="border-b-[1px] bg-[#0084F7] text-white   border-collapse border-[#d8d8d8] primary:rounded-none rounded-[0] primary:order-1 order-6 relative py-7 ">
                  <div className="flex items-center w-full text-center  justify-between  h-full ">
                    <div className="w-[35px] primary:hidden"></div>
                    <IoIosArrowBack
                      width={35}
                      height={35}
                      className="primary:text-primary text-xl primary:block hidden relative -left-2"
                      color="#D8D8D8"
                    />
                    <span>
                      <h1 className="text-xl justify-center w-full   font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl  ">
                          {stats.performanceFormula.neukunde}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[ #D8D8D8]">
                        Neukunde
                      </p>
                    </span>

                    <div className="w-[35px] "></div>
                    <IoIosArrowDown
                      width={35}
                      height={35}
                      color="#D8D8D8"
                      className="absolute right-0 left-0 m-auto -bottom-1 primary:text-primary text-xl primary:hidden"
                    />
                  </div>
                </div>
                <div className="border-[1px] border-t-0  relative  border-collapse border-[#d8d8d8]  primary:rounded-br-[15px]  primary:order-1 order-3">
                  <div className="flex items-center w-full text-center justify-between  my-7 ">
                    <IoIosArrowBack
                      width={35}
                      height={35}
                      color="#D8D8D8"
                      className=" primary:text-primary primary:block hidden text-xl -left-3 relative "
                    />
                    <div className="w-[35px] primary:hidden "></div>

                    <span>
                      <h1 className="text-xl justify-center w-full  font-semibold flex items-center ">
                        <span className="primary:text-[28px] text-xl red-mark ">
                          {stats.performanceFormula.termine}
                        </span>
                      </h1>
                      <p className="font-[500] text-[14px] text-[#868686]">
                        Termine
                      </p>
                    </span>
                    <div className="w-[35px]"></div>
                    <IoIosArrowDown
                      width={35}
                      height={35}
                      color="#D8D8D8"
                      className="absolute right-0 left-0 -bottom-1 m-auto primary:text-primary text-xl primary:hidden"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" secondary:h-[400px] h-full gap-10 flex  secondary:flex-nowrap flex-wrap items-center justify-between w-full"
        style={{ width: "auto" }}
      >
        <div className="secondary:w-[calc(100%-500px)] h-full  h-[400px]">
          <EChart />
        </div>
        <div className="secondary:h-full h-[400px] w-[500px] overflow-hidden  flex-grow-0   bg-glass-strings  sm:rounded-[30px] rounded-[20px]">
          <div className="text-center font-semibold text-white my-5">
            <p className=" primary:text-[28px] text-xl">
              Demnächst Upgrade auf Premium
            </p>
            <p className="primary:text-[16px] text-sm ">
              Schau dir an was kommt
            </p>
            <div className="flex items-center justify-center my-10">
              <Feature />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
