import { Button, Space, Switch } from "antd";
import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MainLogo } from "../../assets/Svgs/GUTERTIPP";
import PromoterPublicService from "../../service/PromoterPublicService";
import { removeToken, storeToken } from "../../utils/LocalStorageHandler";
import FloatLabel from "../FloatingLabel";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";
const Index = () => {
  const { token } = useParams();
  const [promoter, setPromoter] = useState(null);
  const [creditsRedeem, setCreditRedeem] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const getPromoter = useCallback(async () => {
    try {
      removeToken();
      storeToken({
        access_token: token,
        refresh_token: token,
      });
      try {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);

        const { data } = await PromoterPublicService.detail(
          decodedToken.promoterId
        );
        setPromoter(data);
      } catch (error) {}
    } catch (error) {}
  }, [token]);
  useEffect(() => {
    getPromoter();
  }, [getPromoter]);

  // if (!promoter.allowsAutomatedMessaging || !promoter.acceptsAGB)
  //   return (
  //     <div className="max-w-[2000px] m-auto flex justify-center items-center">
  //       <div className="secondary:w-[60%] primary:w-[80%]  m-auto my-40">
  //         <h1 className="text-9xl font-semibold">
  //           Hallo{" "}
  //           <span className="text-blue-main mx-1">{`${
  //             promoter?.firstname ?? ""
  //           } ${promoter?.lastname ?? ""}`}</span>
  //           , um auf Dein Dashboard zugreifen zu können, musst du zuerst
  //           folgende Erlaubnisse erteilen.
  //         </h1>
  //         <div>
  //           <Space className="mt-5">
  //             <Switch
  //               checked={promoter.allowsAutomatedMessaging}
  //               onChange={async (e) => {
  //                 await PromoterPublicService.changeAllowances({
  //                   allowsAutomatedMessaging: e,
  //                 });
  //                 await getPromoter();
  //               }}
  //             />{" "}
  //             <div>
  //               Ich stimme der automatischen Versand von Nachrichten durch
  //               EinGuterTipp an meine E-Mail-Adresse zu.
  //             </div>
  //           </Space>
  //         </div>
  //         <div>
  //           <Space className="mt-5">
  //             <Switch
  //               checked={promoter.acceptsAGB}
  //               onChange={async (e) => {
  //                 await PromoterPublicService.changeAllowances({
  //                   acceptsAGB: e,
  //                 });
  //                 await getPromoter();
  //               }}
  //             />{" "}
  //             <div>
  //               Ich stimme der{" "}
  //               <a href="/agb" target="_blank">
  //                 gesonderten AGBs
  //               </a>{" "}
  //               von EinGuterTipp zu.
  //             </div>
  //           </Space>
  //         </div>
  //       </div>
  //     </div>
  //   );

  if (!promoter) {
    return <LoadingScreen />;
  }
  return (
    <div className="max-w-[2000px] m-auto flex flex-col justify-center items-center">
      <div className="mt-10">
        <MainLogo />
      </div>
      <div className="secondary:w-[60%] primary:w-[80%]  m-auto my-20 px-3">
        <h1 className="text-9xl font-semibold">
          Hallo{" "}
          <span className="text-trkis font-bold mx-1">{`${promoter?.firstname} ${promoter?.lastname}`}</span>
          , willkommen in Deinem{" "}
          <span className="text-blue-main mx-1"> Prämien Portal</span>.{" "}
        </h1>
        <div className="text-text-sec grid gap-10">
          <p>
            Nur wer etwas gutes weiterempfiehlt, gibt auch Anderen die Chance
            etwas gutes kennen zu lernen.{" "}
          </p>
          <p>
            So findest Du hier stets Dein persönliches Dankeschön von{" "}
            {`${promoter?.user_id?.firstname} ${promoter?.user_id?.lastname}`}{" "}
            als Wertschätzung Deines Einsatzes.{" "}
          </p>
        </div>
        <div className="flex  gap-5 my-10 primary:flex-nowrap flex-wrap">
          <div className="grad-one-bg flex items-center h-[90px] justify-center gap-5 text-white rounded-[15px]  sm:w-[500px]  w-full">
            <span className="text-xl font-semibold">GUTHABEN:</span>
            <span className="text-primary font-bold">
              {String((promoter?.credits ?? 0)?.toFixed?.(2)).replace(".", ",")}
              €
            </span>
          </div>
          <FloatLabel
            keepitfocused
            label={"20€-200€"}
            className={
              "h-[90px] flex justify-center border border-[#d8d8d8] p-[30px_25px] rounded-sm "
            }
          >
            <input
              type="number"
              value={creditsRedeem}
              onChange={(e) => setCreditRedeem(e.target.value)}
              className="bg-transparent text-xl font-semibold  outline-none sm:w-[167px] appearance-none"
              placeholder="5-200"
            />
          </FloatLabel>
          <Button
            loading={submitting}
            className="grad-two-bg flex items-center  cursor-pointer h-[90px] justify-center text-white rounded-[15px] w-[330px] text-xl font-semibold"
            onClick={() => {
              if (submitting) return;
              setSubmitting(true);
              PromoterPublicService.createRedeemRequest(creditsRedeem)
                .then((e) => {
                  setPromoter((prev) => ({
                    ...prev,
                    credits: prev.credits - creditsRedeem,
                  }));

                  setCreditRedeem(0);
                })
                .catch((e) => console.log(e))
                .finally(() => setSubmitting(false));
            }}
          >
            EINLÖSEN
          </Button>
        </div>
        <div className="text-text-sec grid gap-10 font-semibold">
          <p>
            Löse Dein Guthaben ein und erhalt Deinen Gutschein in wenigen Tagen
            direkt von Cadooz per Email. Diesen kannst Du dann bei cadooz.com
            nach Belieben in viele tolle Prämien und Gutscheine einlösen.
          </p>
          <p>
            Und so einfach geht's: <br />
            Gehen Sie auf{" "}
            <a
              href="https://www.einloesen.de/"
              target="_blank"
              rel="noreferrer"
              className="text-black underline"
            >
              www.einloesen.de
            </a>
          </p>
        </div>
        <ul className=" w-full animate-fade-right  hero-list text-[#001D47] text-xl font-bold my-10">
          <li className=""> Gutscheincode eingeben</li>
          <li className="">Gutscheine aussuchen </li>
          <li className="">Lieferadresse eingeben</li>
          <li className="">Wunschprodukt per Post oder E-Mail erhalten</li>
        </ul>
        <div className="text-trkis font-semibold text-xl">
          Auswahl aus über 700 Gutscheinen und Sachprämien!
        </div>
        <div>
          <h1 className="text-xl font-bold my-10">
            Rechtlicher Hinweis: Prämien und Guthaben
          </h1>
          <div>
            Dieses Prämienportal ermöglicht es Promotern/-innen, Ihr Guthaben
            einzusehen und einzulösen. Wir möchten ausdrücklich darauf
            hinweisen, dass nur die Verwaltung der Prämien und Guthaben vom
            Seitenbetreiber EinGuterTipp zur Verfügung gestellt wird. Es besteht
            kein Rechtsanspruch gegen EinGuterTipp auf die Verfügbarkeit und
            Gewährung von Prämien oder Guthaben. Für sämtliche Prämien, die
            gegenüber Promotern/-innen zugesagt und einlöst werden, ist Sabine
            Meyer in vollem Umfang verantwortlich. Der Seitenbetreiber hat keine
            Einfluss auf die zwischen{" "}
            {`${promoter?.firstname} ${promoter?.lastname}`} und den
            Promotern/-innen getroffenen Vereinbarungen und übernimmt daher auch
            keine Verpflichtung oder Haftung in diesem Zusammenhang. Die
            Finanzierung und Gewährleistung der Prämien liegt in der alleinigen
            Verantwortung des Partners{" "}
            {`${promoter?.firstname} ${promoter?.lastname}`}. Jegliche
            Ansprüche, Streitigkeiten oder Fragen bezüglich der Prämien oder des
            Guthabens sind direkt zwischen dem Nutzer/Partner und dem jeweiligen
            Promoter zu klären. Bitte beachten Sie, dass die Verfügbarkeit von
            Prämien und Guthaben von verschiedenen Faktoren abhängig ist. So
            kann der Dienst durch den Seitenbetreiber jederzeit angepasst werden
            oder vorübergehenden Einschränkungen unterliegen. Vielen Dank für
            Ihre Nutzung des Prämienportals.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
