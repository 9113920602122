import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class AuthService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/auth`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });

    middleField(this.api, true);
  }

  register({ companyName, firstname, lastname, email, password }) {
    return this.api.post("/register", {
      companyName,
      firstname,
      lastname,
      email,
      password,
      baseUrl: window.location.origin,
    });
  }
  // confirmEmail({ userId }) {
  //   return this.api.post("/confirmEmail", { id: userId });
  // }

  login({ email, password }) {
    return this.api.post("/login", {
      email,
      password,
      baseUrl: window.location.origin,
    });
  }

  confirmEmail(id) {
    return this.api.post("/confirmEmail", { id });
  }

  refresh({ accessToken, refreshToken }) {
    var raw = JSON.stringify({ accessToken, refreshToken });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
    };

    return fetch(this.baseURL + "/refresh", requestOptions);
  }

  requestPasswordReset({ email }) {
    return this.api.post("/requestPasswordReset", { email });
  }
  confirmEmailSend() {
    return this.api.post("/confirmEmailSend", {
      baseUrl: window.location.origin,
    });
  }

  resetPassword({ resetToken, newPassword }) {
    return this.api.post("/resetPassword", {
      resetToken,
      newPassword,
    });
  }
}

export default new AuthService();
