import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FloatingLabel from "../../FloatingLabel/";
const HTMLTextEditor = ({ quillattributes, update, label = "", value="", configChange, saveConfig }) => {
  // const [ownValue, setOwnValue] = useState("");

  useEffect(() => {
    configChange(value)
  }, [value])

  return (
    <div {...quillattributes}>
      <FloatingLabel
        label={label}
        keepitfocused
        focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
      >
        <Button
          className="absolute top-[10px] right-[10px] text-[14px] font-medium"
          type="primary"
          onClick={() => {saveConfig(value)}}
        >
          {" "}
          Speichern
        </Button>
        <ReactQuill
          value={value}
          onChange={(e) => {
            configChange(e);
          }}
        />
      </FloatingLabel>
    </div>
  );
};

export default HTMLTextEditor;
