"use client";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const Slide = ({ items, className, speed }) => {
  return (
    <Swiper
      dir="ltr"
      loop={true}
      autoplay={{
        delay: 1700,
        disableOnInteraction: false,
      }}
      speed={speed}
      slidesPerView="auto"
      spaceBetween={0}
      grabCursor={true}
      modules={[FreeMode, Autoplay]}
      className={`${className} h-min`}
      onTouchStart={(swiper) => {
        swiper.autoplay.stop();
      }}
      onTouchEnd={(swiper) => {
        swiper.autoplay.start();
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide
          key={index}
          style={{ width: "fit-content", paddingLeft: 0 }}
          className={`my-auto`}
        >
          {item}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slide;
