import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from "react";
import Box from "../Layout/Box";
import SwitchTitle from "../shared/SwitchTitle";
import { useParams } from "react-router-dom";
import { useMessageContext } from "../../../../context/messageContext";
import MediaUpload from "../shared/MediaUpload";
import Inputs from "../../shared/Input";
import Button from "../shared/Button";
import { PopUpComponent } from "../../../ConfirmPopup";

const LocalReviews = forwardRef(
  ({ landingPageData: { activateLocalReviews }, localReviewsData }, ref) => {
    const MIN = 3;
    const MAX = 12;
    const customerImageRefs = useRef([]);
    const { id: ladingpageId } = useParams();
    const { showMessage } = useMessageContext();
    const [isActivateLocalReviews, setIsActivateLocalReviews] =
      useState(activateLocalReviews);
    const [shouldScroll, setShouldScroll] = useState(false);
    const [localReviews, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case "SET_REVIEWS":
          return action.payload.reviews;
        case "ADD_REVIEW":
          return [
            ...state,
            {
              reviewedby: "",
              stars: 5,
              comment: "",
              customerImage: "",
              landingpage_id: ladingpageId,
            },
          ];
        case "DELETE_REVIEW":
          const updatedReviews = [...state];
          updatedReviews.splice(action.payload.index, 1);
          return updatedReviews;
        case "UPDATE_COMMENT":
          const updatedReviewsComment = [...state];
          updatedReviewsComment[action.payload.index].comment =
            action.payload.newComment;
          return updatedReviewsComment;
        case "UPDATE_NAME":
          const updatedReviewsName = [...state];
          updatedReviewsName[action.payload.index].reviewedby =
            action.payload.newName;
          return updatedReviewsName;
        case "UPDATE_IMAGE":
          const updateImage = [...state];
          updateImage[action.payload.index].customerImage =
            action.payload.newImage;
          return updateImage;
        default:
          return state;
      }
    }, localReviewsData);
    const containerRef = useRef(null);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("localReviews.save()");

        // Saving images
        const localReviewsWithNewImages = await Promise.all(
          customerImageRefs.current.map(async (ref, index) => {
            const newImage = await ref.saveLocalToCloud();
            return {
              ...localReviews[index],
              customerImage: newImage.success
                ? newImage.secure_url
                : newImage.unchanged_secure_url,
            };
          })
        );

        return {
          localReviewsData: localReviewsWithNewImages,
          landingPageData: {
            activateLocalReviews: isActivateLocalReviews,
          },
        };
      },
    }));

    useEffect(() => {
      if (shouldScroll && containerRef.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        setShouldScroll(false); // Setze die Flagge zurück
      }
    }, [shouldScroll]);

    // Update imageRefs when localrevies change
    useEffect(() => {
      customerImageRefs.current = Array.from(
        { length: localReviews.length },
        (_, index) => customerImageRefs.current[index]
      );
    }, [localReviews]);

    return (
      <Box>
        <SwitchTitle
          deactivated={localReviews.length < MIN}
          checked={isActivateLocalReviews}
          onChange={(e) => {
            if (e == true && localReviews.length < MIN) {
              showMessage("error", `Bitte min. ${MIN} Testimonials anlegen!`);
            } else {
              setIsActivateLocalReviews(e);
            }
          }}
        >
          Testimonials
          <img src="/images/logo.png" alt="" width="30" className="m-auto" />
        </SwitchTitle>

        <div
          ref={containerRef}
          className="flex gap-[17.5px] lg:grid-cols-3 sm:grid-cols-2 sm:grid overflow-x-scroll mt-10"
        >
          {/**Cards */}
          {localReviews.map((review, index) => (
            <LocalReviewEditCard
              activateLocalReviews={isActivateLocalReviews}
              key={index}
              comment={review}
              onChangeComment={(e) =>
                dispatch({
                  type: "UPDATE_COMMENT",
                  payload: { index: index, newComment: e.target.value },
                })
              }
              onChangeName={(e) =>
                dispatch({
                  type: "UPDATE_NAME",
                  payload: { index: index, newName: e.target.value },
                })
              }
              onDelete={() => {
                PopUpComponent({
                  onConfirm: () => {
                    if (localReviews.length <= MIN) {
                      setIsActivateLocalReviews(false);
                    }

                    if (localReviews.length === MIN)
                      showMessage(
                        "info",
                        `Testimonials deaktiviert: Bitte mindestens ${MIN} Testimonials anlegen.`
                      );

                    dispatch({
                      type: "DELETE_REVIEW",
                      payload: { index: index },
                    });
                  },
                  heading:
                    "Bist Du sicher, dass Du dieses Testimonial löschen möchtest?",
                  confirmButtonText: "Löschen",
                });
              }}
              imageRef={(ref) => {
                customerImageRefs.current[index] = ref;
              }}
              imageUrl={review.customerImage}
              setImageUrl={(newImageUrl) =>
                dispatch({
                  type: "UPDATE_IMAGE",
                  payload: { index: index, newImage: newImageUrl },
                })
              }
            />
          ))}

          {/** Delete button desktop */}
          <div
            className={`${
              localReviews.length >= MAX ? "" : "sm:flex"
            } hidden flex-col justify-center items-center p-[12px_20px] rounded-[10px] bg-white text-center text-black  text-base  font-semibold cursor-pointer w-full`}
            onClick={() => {
              if (localReviews.length >= MAX - 1) {
                showMessage(
                  "info",
                  "Maximale Anzahl an Testimonials erreicht."
                );
              }
              dispatch({
                type: "ADD_REVIEW",
              });
            }}
          >
            <span className="mr-5 text-[35px]">+</span>
            Weitere Kundenstimme Hinzufügen
          </div>
        </div>

        {/** Delete button Mobile */}
        <Button
          text="Weitere Kundenstimme Hinzufügen"
          className={`sm:hidden ${
            localReviews.length >= MAX ? "hidden" : "block"
          }`}
          onClick={() => {
            if (localReviews.length >= MAX - 1) {
              showMessage("info", "Maximale Anzahl an Testimonials erreicht.");
            }
            dispatch({
              type: "ADD_REVIEW",
            });
            setShouldScroll(true);
          }}
        />
      </Box>
    );
  }
);

const LocalReviewEditCard = ({
  activateLocalReviews,
  onChangeName,
  onChangeComment,
  comment,
  onDelete,
  imageUrl,
  setImageUrl,
  imageRef,
}) => {
  return (
    <div className="flex min-w-[300px] flex-col rounded-11xl bg-white p-[20px] gap-[20px] sm:min-w-[0px]">
      <div className="flex gap-[20px] items-center justify-left">
        <MediaUpload
          ref={imageRef}
          type="avatar"
          url={imageUrl}
          setUrl={setImageUrl}
          id={`avatar-img ` + Math.random()}
        />
        <div className="text-[16px] font-semibold text-black ">
          Bild hochladen
        </div>

        <div
          className="ml-auto text-[40px] font-bold cursor-pointer"
          onClick={onDelete}
        >
          -
        </div>
      </div>

      <Inputs
        label={"Name des Rezensionverfassers"}
        value={comment.reviewedby}
        onChange={onChangeName}
        emptyCheck={activateLocalReviews}
      />

      <Inputs
        type="textarea"
        label={"Kommentar"}
        value={comment.comment}
        onChange={onChangeComment}
        emptyCheck={activateLocalReviews}
      />
    </div>
  );
};

export default LocalReviews;
