import { Modal } from "antd";
import React, { useState } from "react";

const Footer = ({ landingPageData, ladingPageId, promoter_id, type, user }) => {
  const [impressum, setImpressum] = useState(false);

  return (
    <div className="w-full px-3   bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
      <div className="flex secondary:w-[70%] m-auto  justify-center sm:justify-between font-semibold text-[16px] flex-wrap gap-20 my-5 w-full ">
        <h1>© Copyright {`${user.firstname} ${user.lastname}`}</h1>
        <div className="flex items-center gap-10">
          {user?.defaultImpressumLink ? (
            <a
              className="cursor-pointer "
              rel="noreferrer"
              href={`${
                !user?.defaultImpressumLink?.includes?.("https://")
                  ? "https://"
                  : ""
              }${user?.defaultImpressumLink}`}
              target="_blank"
            >
              Impressum
            </a>
          ) : landingPageData?.specificImpressumText ? (
            <a
              className="cursor-pointer "
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setImpressum(landingPageData?.specificImpressumText);
              }}
            >
              Impressum
            </a>
          ) : (
            user?.defaultImpressumText?.length > 1 && (
              <a
                className="cursor-pointer "
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  setImpressum(user?.defaultImpressumText);
                }}
              >
                Impressum
              </a>
            )
          )}
        </div>
      </div>

      <Modal
        title="Impressum"
        onCancel={() => setImpressum(false)}
        open={impressum}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: impressum?.replace?.(/\n/g, "<br>"),
          }}
        ></div>
      </Modal>
    </div>
  );
};

export default Footer;
