const Features = () => {
  return (
    <div className="relative rounded-[20px] self-end bg-gray xs:scale-110 xss:scale-100 scale-90  box-border w-[380px] h-[230px] overflow-hidden text-left text-xs text-text-sec font-copy border-[1px] border-solid border-grey-accent">
      <div className="absolute top-[10px] w-full text-center text-base font-semibold text-text-prim">
        Features
      </div>
      <div className="absolute top-[37px] left-[20px] rounded-3xs bg-kacheln-bg overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-whatsapp-1.png"
          />
          <div className="w-[122px] flex flex-col items-start justify-start">
            <div className="relative font-normal flex items-center w-[272px]">
              WhatsApp Integration
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[48px] left-[174px] rounded-3xs bg-kacheln-bg overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-stern-50.png"
          />
          <div className="w-[143px] flex flex-col items-start justify-start">
            <div className="relative font-normal">komplexe Kampagnen</div>
          </div>
        </div>
      </div>
      <div className="absolute top-[110px] left-[28px] rounded-3xs bg-kacheln-bg box-border w-[266px] overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-terminerinnerungen-50.png"
          />
          <div className="w-[266px] flex flex-col items-start justify-start">
            <div className="relative font-normal">
              Individualisierbare Benachrichtigungen
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[171px] left-[123px] rounded-3xs bg-kacheln-bg box-border w-[205px] overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-roman-1.png"
          />
          <div className="w-[200px] flex flex-col items-start justify-start">
            <div className="relative font-normal">
              Bewertungsportal integration
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[142px] left-[16px] rounded-3xs bg-kacheln-bg box-border w-[240px] overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="w-[205px] flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-roboter-1.png"
          />
          <div className="w-[266px] flex flex-col items-start justify-start">
            <div className="relative font-normal">
              noch mehr Automatisierung & KI
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[170px] left-[40px] rounded-3xs bg-kacheln-bg overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-detailansicht-1.png"
          />
          <div className="flex flex-col items-start justify-start">
            <div className="relative font-normal">u.v.m.</div>
          </div>
        </div>
      </div>
      <div className="absolute top-[79px] left-[81px] rounded-3xs bg-kacheln-bg box-border w-[221px] overflow-hidden flex flex-col p-2.5 items-start justify-start border-[1px] border-solid border-grey-accent">
        <div className="w-[201px] flex flex-row items-start justify-start gap-[5px]">
          <img
            className="relative w-4 h-4 overflow-hidden shrink-0"
            alt=""
            src="/images/icons-8-euro-1.png"
          />
          <div className="w-[122px] flex flex-col items-start justify-start">
            <div className="relative font-normal flex items-center w-[280px]">
              Umsatz-und Einkommensplaner
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
