import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import CrudService from "../../../service/CrudService";
import UploadToCloudService from "../../../service/UploadToCloudService"; // Importieren des Upload-Service

const Testimonials = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [modalVisible, setModalVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await CrudService.search(
        "Testimonial",
        pagination.pageSize,
        pagination.current,
        {}
      );
      setData(response.data.items);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Laden");
    }
    setLoading(false);
  };

  const handleCreate = () => {
    setEditingRecord(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await CrudService.delete("Testimonial", id);
      message.success("Erfolgreich gelöscht");
      fetchData();
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Löschen");
    }
  };

  const handleSave = async () => {
    if (uploading) return;
    setUploading(true);
    try {
      const values = await form.validateFields();

      if (values.image && values.image.file) {
        const info = await UploadToCloudService.upload(
          values.image.file.originFileObj
        );
        values.image = info.secure_url; // Replace image field with the URL
      }

      if (editingRecord) {
        await CrudService.update("Testimonial", editingRecord._id, values);
        message.success("Erfolgreich gespeichert");
      } else {
        await CrudService.create("Testimonial", values);
        message.success("Erfolgreich erstellt");
      }
      fetchData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Speichern");
    } finally {
      setUploading(false);
    }
  };

  const columns = [
    {
      title: "Bild",
      dataIndex: "image",
      key: "image",
      render: (text) => <img src={text} alt="avatar" style={{ width: 50 }} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Titel/Job",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Bewertungstext",
      dataIndex: "reviewText",
      key: "reviewText",
      render: (a) => <div className="max-w-[200px] truncate">{a}</div>,
    },
    {
      title: "Aktiv",
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <Switch
          checked={record.active}
          onChange={async (checked) => {
            record.active = checked;
            await CrudService.update("Testimonial", record._id, {
              active: checked,
            });
            fetchData();
          }}
        />
      ),
    },
    {
      title: "Aktion",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Bearbeiten
          </Button>
          <Popconfirm
            title="Sicher?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Löschen
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Button type="primary" onClick={handleCreate} className="mb-4">
          Neues Testimonial
        </Button>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="_id"
          pagination={{
            ...pagination,
            onChange: (page) => setPagination({ ...pagination, current: page }),
          }}
        />
      </div>

      <Modal
        open={modalVisible}
        title={editingRecord ? "Testimonial bearbeiten" : "Neues Testimonial"}
        onCancel={() => setModalVisible(false)}
        onOk={handleSave}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="image"
            label="Bild"
            rules={[{ required: true, message: "Bitte ein Bild hochladen" }]}
          >
            <Upload listType="picture" maxCount={1}>
              <Button icon={<UploadOutlined />}>Bild hochladen</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Bitte den Namen eingeben" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="jobTitle"
            label="Titel/Job"
            rules={[
              { required: true, message: "Bitte den Titel/Job eingeben" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reviewText"
            label="Bewertungstext"
            rules={[
              { required: true, message: "Bitte den Bewertungstext eingeben" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="active" label="Aktiv" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Testimonials;
