import React from "react";
import HeroFinance from "./HeroFinance";
import HeroStromGas from "./HeroStromGas";
import HeroProperty from "./HeroProperty";

const Hero = ({ type, landingPageData }) => {
  switch (type) {
    case "finance":
    case "individual":
      return <HeroFinance landingPageData={landingPageData} />;
    case "stormgas":
      return <HeroStromGas landingPageData={landingPageData} />;
    case "property":
      return <HeroProperty landingPageData={landingPageData} />;
  }
};

export default Hero;
