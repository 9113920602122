import React from "react";
const Hero = () => {
  return (
    <div className="sm:w-[90%] secondary:p-10 p-2 w-full mx-auto  flex justify-between ">
      <div className="w-full secondary:basis-[90%] flex flex-col gap-3 items-start justify-start">
        <div className="text-[16px] sm:text-[20px] font-semibold !text-[#004D65] mt-10 mx-5 sm:mx-0">
          Für Finanzdienstleister & Direktvertriebler!
        </div>
        <div className="hero-text animate-fade-right lg:text-[34px] text-[26px]  sm:text-[30px]  font-[600] mb-4 mx-5 sm:mx-0">
          Die einzige Software, die Du heute für Dein Empfehlungsmarketing
          brauchst! Jede Woche automatisiert top Empfehlungen erhalten.
        </div>
        <div className="relative top-[-35px] overflow-hidden sm:hidden  animate-fade-left w-full justify-center m-auto flex h-[643px]  ">
          <img
            src={"/images/Iphone14.png"}
            alt="Iphone14"
            className="  w-[400px] "
          />
        </div>
        <div className="sm:text-[20px] text-base animate-fade-right  font-[600] text-[#868686] mt-[-130px] sm:mt-[0px] mx-5 mx-auto container max-w-[90vw]">
          Smartes Empfehlungsmarketing, so wie es heute funktioniert. Alles an
          einem Platz: Erhalten, verwalten, Wiedervorlagen, Automatisierungen,
          Belohnungen.
        </div>
        <div className="primary:mt-[-120px] mt-10 container max-w-[90vw] sm:max-w-6xl mx-auto sm:hidden flex justify-center border-4 border-solid border-[#001d47] rounded-xl shadow-2xl overflow-hidden ">
          <video
            src="/Dein Loft Film - EinGuterTipp.mp4"
            controls
            preload="metadata"
            poster={"/thumb.png"}
          />
        </div>
        <div className="mt-2 mb-5 flex-wrap sm:mx-0 flex primary:flex primary:w-auto w-full justify-left mx-[17vw] gap-3">
          <button className="w-[283px] whitespace-nowrap bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000]  animate-jump-in py-[10px] sm:mr-3  justify-center items-center flex text-center  secondary:py-[20px]   rounded-[50px] text-white font-[600] text-[20px] lg:mt-11 lg:mb-8 mt-7 mb-4">
            <a href="/erstgesprach">Kostenloses Erstgespräch</a>
          </button>

          <div className="flex items-center secondary:h-auto sm:hidden  primary:flex-nowrap flex-wrap sm:justify-between justify-center ">
            <ul className=" w-full animate-fade-right  hero-list text-[#001D47] secondary:text-[26px]  text-base sm:text-[20px] font-bold my-10">
              <li className="">Immer & überall</li>
              <li className="">100% DSGVO konform </li>
              <li className="">Automatisches Prämiensystem </li>
              <li className="">Einfach und für jeden umsetzbar</li>
            </ul>
          </div>
        </div>
        <div className="flex items-center w-full hidden sm:flex secondary:h-auto  primary:flex-nowrap flex-wrap sm:justify-between justify-center mb-10">
          <ul className=" w-full animate-fade-right  hero-list text-[#001D47] secondary:text-[26px]  text-base sm:text-[20px] font-bold mt-0 ">
            <li className="">Immer & überall</li>
            <li className="">100% DSGVO konform </li>
            <li className="">Automatisches Prämiensystem </li>
            <li className="">Einfach und für jeden umsetzbar</li>
          </ul>
          <div className="overflow-hidden secondary:hidden  animate-fade-left w-full justify-center m-auto sm:flex hidden   ">
            <img
              src={"/images/Iphone14.png"}
              alt="Iphone14"
              className="  w-[150px]"
            />
          </div>
        </div>
      </div>
      <img
        src={"/images/Iphone14.png"}
        alt="Iphone14"
        className="basis-[40%] secondary:block hidden  relative right-[-130px] animate-fade-left"
        style={{ maxWidth: "unset" }}
      />
    </div>
  );
};

export default Hero;
