import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PublicService from "../../service/PublicService";
import QuestionService from "../../service/QuestionService";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen";
import Questionare from "./Questionare";
import { Finance } from "./data/Data";
const MainQuestionare = () => {
  const { type, id } = useParams();
  const [questions, setQuestions] = useState(null);
  useEffect(() => {
    PublicService.getQuestions(id).then(({ data }) => {
      console.log(data);
      setQuestions(data);
    });
  }, [id]);
  if (!questions) {
    return <Skeleton active />;
  }

  if (!questions) {
    return <LoadingScreen />;
  }
  switch (type) {
    case "finance":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],

            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(250, 250, 250, 0.30)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Manrope",
            rounded: true,
            buttonbg: "#001D47",
          }}
        />
      );

    case "stormgas":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(70, 70, 174, 0.60)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "#DBEF5D",
            font: "Lexend",
            rounded: false,
            buttonbg: "#4646AE",
          }}
        />
      );

    case "property":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(241, 243, 246, 0.8)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Roboto Slab",
            rounded: true,
            buttonbg: "#0084F7",
          }}
        />
      );

    case "individual":
      return (
        <Questionare
          data={questions?.map?.((e) => ({
            question: e.questionText,
            answers: e.answerOptions ?? [],
            bg: e.backgroundImage,
          }))}
          styles={{
            modelBg: "rgba(250, 250, 250, 0.30)",
            formBgOne: questions[0]?.backgroundImage,
            formBgTwo: "white",
            font: "Manrope",
            rounded: true,
            buttonbg: "#001D47",
          }}
        />
      );

    default:
      return <></>;
  }
};

export default MainQuestionare;
