import React from "react";

const BlackArrow = ({ show, type }) => {
  return (
    show && (
      <div
        className={`absolute bottom-0 left-0 right-0 h-10 translate-y-full
           secondary:hidden block ${type === "finance" ? "px-3" : "p-0"}`}
      >
        <div className="h-full bg-black bg-opacity-50 clip-triangle"></div>
      </div>
    )
  );
};

export default BlackArrow;
