import React, { useEffect, useState } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import CrudService from "../../../service/CrudService";
import PublicService from "../../../service/PublicService";
import CollapseItem from "./CollapseItem";
import ReviewSlider from "./ReviewSlider";
const items = [
  {
    title: "Muss ich etwas installieren?",
    desc: "Um EinGuterTipp nutzen zu können, brauchst du keine Software installieren. Du kannst es auf allen Geräten nutzen.",
  },
  {
    title: "Brauche ich das Coaching zwingend?",
    desc: "Nein. Mit der Nutzung erhältst Du Zugang zu einem sehr schlanken Videokurs mit Gesprächsleitfäden und 1:1 Umsetzungserklärungen, damit Du schnell und unkompliziert starten kannst. Mit dem kostenlosen Onboarding findest Du Dich auch umgehend zurecht. So hast du alles für einen guten Start. Das Coaching kannst du bei Bedarf jederzeit nachordern.",
  },
  {
    title: "Sind meine Kundendaten sicher?",
    desc: "Selbstverständlich. Deinen Daten liegen auf Deutschen und Europäischen Sicherheitsservern. So werden höchste Sicherheit und die DSGVO gewährleistet.",
  },
  {
    title: "Brauche ich dafür eine Webseite ?",
    desc: "Du brauchst weder eine Webseite, noch sonst eine digitale Präsens. Du brauchst auch keine besonderen Kenntnisse oder Fähigkeiten im Umgang mit Computern oder Software.",
  },
];
const Index = () => {
  const [prices, setPrices] = useState({
    month: 299,
    sixMonths: 199,
    twelveMonths: 149,
    title: "Standard",
  });
  const getPrices = async () => {
    const data = await PublicService.getSubscriptionInfo();
    setPrices((prev) => ({ ...prev, ...data?.data }));
  };
  useEffect(() => {
    getPrices();
  }, []);
  const [textValue, setTextValue] = useState("");
  const [email, setEmail] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [tab, setTab] = useState("twelveMonths");

  return (
    <div className=" rounded-[30px] bg-white" id="onboard">
      <div className="gray-bg-gradient sm:p-[6rem]  sm:w-[90%] w-[99%] m-auto">
        <h1 className="font-bold text-center xl:text-primary text-responsiveHeading sm:text-start ">
          <u className="text-trkis">Coaching</u>
          <br className="sm:hidden" />
          <span>
            <span className="hidden sm:inline">-</span> Wir machen Dich fit
          </span>
        </h1>
        <div className="my-10 sm:w-auto w-[80%] font-semibold text-[#868686] text-base sm:text-start text-center sm:text-[20px] sm:m-0 m-auto ">
          <p>
            Wichtiger als die Tatsache, das Du unsere Software nutzt ist, dass
            du Erfolg hast. Solltest Du also irgendwie nicht weiter kommen oder
            Hilfe benötigen, sind wir zur Stelle. Auf Wunsch unterstützen wir
            Dich zusätzlich mit LiveCalls, erweitertem OnlineKurs, helfen beim
            Videodreh, dem einrichten der Landingpages sowie der Implementierung
            in Deinen Arbeitsalltag und vieles mehr. Wir begleiten Dich Schritt
            für Schritt, so dass Du in wenigen Wochen zum absoluten
            Empfehlungsprofi wirst!
          </p>
        </div>
        <div className="m-auto mt-5 w-[400px] xl:w-full rounded-[30px] relative flex flex-wrap px-[4rem] xl:flex-nowrap xl:gap-0 gap-20  bg-white justify-between overflow-hidden py-10">
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              Live Calls
            </h1>
            <img
              src="/images/morflax-studio-18 1.png"
              alt=""
              className="mt-2 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8]  rotate-90 w-[80%] m-auto xl:block hidden" />
          <div className="flex flex-col items-center justify-center w-full">
            <h1 className="text-center text-[20px] font-semibold">
              perfekte Vorbereitung
            </h1>
            <img
              src="/images/settingchaticons.png"
              alt=""
              className="mt-10 w-[250px]"
            />
          </div>
          <hr className="bg-[#D8D8D8] text-[#D8D8D8] rotate-90 w-[80%] overflow-hidden m-auto xl:block hidden" />
          <div className="flex flex-col items-start w-full h-full ">
            <h1 className=" w-full text-[20px]  text-center font-semibold">
              Empfehlungen erhalten
            </h1>
            <div className="xl:hidden flex h-[400px]"></div>
            <img
              src="/images/myhandwithheart.png"
              className="mt-10 xl:w-[330px] w-[450px]  m-auto absolute  xl:right-0 xl:bottom-[-15%] bottom-0 right-0"
              alt=""
            />
          </div>
        </div>

        <div className="my-10 sm:text-start text-center sm:w-auto w-[80%] m-auto">
          <h1 className="text-xl font-bold sm:text-primary">
            Was Du noch erwarten kannst
          </h1>
          <div className="my-10 font-semibold text-base sm:text-xl text-[#868686] w-full">
            <p>
              Wir helfen Dir z.B. dabei das richtige Drehbuch zu entwickeln und
              erstellen Dein Video für Dich. Wir entwickeln die
              verkaufpsychologisch optimierte Texte für Deine Landingpage und Du
              erhältst Ideen und Möglichkeiten wie Du Dich wirklich von der
              Masse abheben kannst und zu etwas erzählenswertem wirst.
            </p>
          </div>
        </div>
        <div className="my-10 grid xl:grid-cols-2 xl:grid-rows-2 grid-cols-1 grid-rows-4  gap-3 text-center text-base sm:text-xl font-semibold  sm:w-auto w-[80%] m-auto">
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            In wöchentlichen LiveCalls entwickeln individuelle Lösungen für Dich
            und Deine Dienstleistungen.
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Natürlich kannst Du verkaufen. Du erhältst weitere wertvolle und
            wichtige Feinheiten, die Dein Empfehlungsmarketing auch nachhaltig
            machen.
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Absolute TOP Vorlagen und Skripte, die nichts dem Zufall überlassen.
          </div>
          <div className="rounded-[20px] border-[1px] px-16 py-10 bg-white border-[#D8D8D8] flex items-center">
            Durch unser cleveres System schaffst Du es Deine Bestandskunden
            nebenbei doch noch zu motivieren Dich weiter zu empfehlen.
          </div>
        </div>
      </div>{" "}
      <div className="m-auto my-20  sm:w-[90%]   w-[80%] ">
        <div className="makeyoufit-card text-center font-bold xl:text-[46px] text-[26px] md:text-[32px] text-white xs:py-14 py-6 xs:px-16 px-0">
          <h1 className="w-[60%]  m-auto my-10">
            Stell Dir vor, Deine Empfehlungen würden sich bei Dir melden?
          </h1>
        </div>

        <div className="my-20 sm:mt-[14rem] flex-col flex relative">
          <div
            className="static block sm:hidden rounded-3xl aspect-square"
            style={{
              backgroundImage: "url(/images/frame-126@2x.png)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top",
            }}
          ></div>
          <div className="grub-dich-card secondary:w-[90%] w-full rounded-[30px] text-white sm:px-10 px-3 py-4 sm:py-12">
            <div className="w-[90%] flex">
              <div className="w-full xl:w-3/4 secondary:w-full">
                <h1 className="font-bold text-white text-responsiveHeading sm:text-primary">
                  Grüß Dich!
                </h1>
                <p className="sm:text-[1.25rem] text-base primary:w-[90%] w-auto font-[600] my-10 text-[#D8D8D8]">
                  “Seit 23 Jahren bin ich Finanzdienstleister mit Leib und
                  Seele. Es gibt eine Menge Gründe Empfehlungsmarketing heute
                  völlig anders zu denken! Aus genau diesem Grund gibt es
                  EinGuterTipp und ich würde mich freuen Dich sehr bald an Board
                  begrüßen zu dürfen. Mein Versprechen an Dich - Du freust Dich
                  sogar mehr als ich!” 😄
                </p>
                <h2 className="flex items-start gap-2 my-10">
                  <img
                    src="/images/icon03-21.png"
                    alt="heart"
                    className="mt-1"
                  />
                  <p className="sm:text-[1.25rem] text-base font-[600]">
                    Sascha Emmerich, Founder
                  </p>
                </h2>
              </div>
              <div className="items-center justify-center hidden w-0 xl:w-1/4 secondary:hidden xl:flex">
                <img
                  src="/images/frame-126@2x.png"
                  alt=""
                  className=" hidden secondary:hidden xl:block w-[400px] "
                />
              </div>
            </div>
            <img
              src="/images/frame-126@2x.png"
              alt=""
              className="secondary:absolute  secondary:block hidden  right-[0] top-[-100px] w-[300px] static"
            />
          </div>
        </div>
        <h1 className="my-10 font-bold sm:text-primary text-responsiveHeading">
          Häufige Fragen:
        </h1>
        <div className="transition-all ">
          {items.map((e, i) => (
            <CollapseItem key={i} {...e} />
          ))}
        </div>
        <div className="px-3 py-5 font-bold text-center text-white makeyoufit-second-card text-responsiveHeading xl:text-primary sm:py-10 sm:px-16 ">
          <div className="">
            <h1> Keine teuren Leads</h1>
            <h1>kaufen müssen um neue Kunden zu gewinnen.</h1>
          </div>
          <button className="bg-[#001D47] text-base sm:text-[20px] mt-10  py-[1.25rem] px-[2.1875rem] rounded-[3.125rem]">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.eingutertipp.de/register"
            >
              Jetzt Kunden gewinnen
            </a>
          </button>

          {/* <Alert
            className="mt-4"
            style={{ marginInline: "2rem" }}
            type="info"
            message={
              <>
                <div>
                  Unser Launchtermin steht noch bevor. Deshalb sind aktuell die
                  meistenFunktionen deaktiviert. Buche hier einen Telefontermin,
                  sofern Du noch Fragen hast. Voraussetzung ist, dass Du Dir die
                  Live-Demo angesehen hast.
                </div>

                <a href="https://calendly.com/saschaemmerich/egt-startergesprach">
                  Jetzt Demo buchen
                </a>
              </>
            }
          /> */}
        </div>
        <div className="mt-20 " id="contact">
          <h1 className="flex items-center ">
            <img src="/images/logo.png" alt="" srcSet="" width="50" />
            <p className="font-bold sm:text-primary text-responsiveHeading">
              -voller Support
            </p>
          </h1>
          <div className="my-10 text-responsiveHeading sm:text-[28px] text-[#868686] font-semibold ">
            {" "}
            <p>Du bist Dir noch unsicher und hast Fragen an uns?</p>
            <p> Dann schreib uns hier einfach Dein Anliegen...</p>
          </div>
        </div>
        <input
          type="text"
          placeholder="deinemail@email.com"
          value={supportEmail}
          onChange={(e) => setSupportEmail(e.target.value)}
          className="rounded-xl outline-none text-base sm:text-xl font-semibold bg-[#fafafa] py-[20px] mb-10 px-[40px]  w-full "
        />
        <div className="grid ">
          <textarea
            name=""
            id=""
            placeholder="schütte Dein Herz aus..."
            className="w-full text-[#868686] bg-[#fafafa] p-3 outline-0 text-base sm:text-xl font-semibold resize-none border-none   rounded-[20px_20px_20px_0px] "
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            rows="6"
          ></textarea>
          <div className="relative flex-grow hidden sm:flex">
            <div className="btn-div "></div>
            <button
              className="bg-[#001D47] rounded-31xl text-white  font-semibold"
              style={{ width: 366, height: 64, marginTop: 20 }}
              onClick={async () => {
                try {
                  PublicService.requestSupport({
                    type: "Client Support",
                    theme: "Anfrage LP",
                    email: supportEmail,
                    message: textValue,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Absenden
            </button>
          </div>
          <button
            className="bg-[#001D47] rounded-31xl text-white  font-semibold w-full sm:hidden py-[15px] text-base"
            style={{ marginTop: 20 }}
            onClick={async () => {
              try {
                PublicService.requestSupport({
                  type: "Client Support",
                  theme: "Anfrage LP",
                  email: supportEmail,
                  message: textValue,
                });
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Absenden
          </button>
        </div>
        <div
          className="mt-20"
          style={{ marginTop: "8rem", marginBottom: "2rem" }}
        >
          <h1 className="font-bold text-center sm:text-primary text-responsiveHeading sm:text-start">
            Nichts mehr verpassen.
          </h1>
          <h1 className="font-bold text-center sm:text-primary text-responsiveHeading sm:text-start">
            Einfach anmelden.
          </h1>
        </div>
        <div className="flex "></div>
        <div className="relative text-center text-text-sec">
          <div className="absolute hidden xl:block  top-[-100px] right-[-100px] w-[526.53px] h-[316.29px]">
            <img
              className="absolute top-[58px] left-[340px] w-[103.69px] h-[101.87px] object-cover opacity-[0.4]"
              alt=""
              src="/images/morflaxstudio514@2x.png"
            />
            <img
              className="absolute top-[160.22px] left-[251.5px] w-[158.83px] h-[156.07px] object-cover opacity-[0.6]"
              alt=""
              src="/images/morflaxstudio610@2x.png"
            />
            <img
              className="absolute top-[141px] left-[0px] w-[123.3px] h-[121.15px] object-cover opacity-[0.4]"
              alt=""
              src="/images/morflaxstudio515@2x.png"
            />
            <img
              className="absolute top-[104.97px] left-[349.35px] w-[177.18px] h-[174.08px] object-cover"
              alt=""
              src="/images/morflaxstudio516@2x.png"
            />
            <img
              className="absolute top-[148px] left-[127px] w-[153.37px] h-[153.37px] object-cover opacity-[0.4]"
              alt=""
              src="/images/morflaxstudio517@2x.png"
            />
            <img
              className="absolute top-[18.03px] left-[39.43px] w-[207.72px] h-[204.21px] object-cover opacity-[0.6]"
              alt=""
              src="/images/morflaxstudio76@2x.png"
            />
            <img
              className="absolute top-[0px] left-[172px] w-[216.37px] h-[216.38px] object-cover opacity-[0.6]"
              alt=""
              src="/images/morflaxstudio611@2x.png"
            />
          </div>

          <div className="flex items-start justify-between xl:flex-nowrap flex-wrap z-[9999]">
            <input
              type="text"
              placeholder="deinemail@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-31xl text-xl font-semibold bg-[#fafafa] py-[20px]  relative z-50 mb-10 px-[40px]  w-full xl:w-[70%] "
            />
            <div className="relative w-full min-fit xl:w-auto">
              <div
                className="min-w-fit z-[1000] rounded-31xl relative  [background:linear-gradient(94.87deg,_#00def7,_#0084f7_49.48%,_#001d47)] shadow-[0px_0px_26.71px_#06def8]  overflow flex flex-col py-5 px-[77px] xl:w-auto w-full justify-center text-white cursor-pointer items-center"
                onClick={async () => {
                  try {
                    await PublicService.subscribeNewsletter({ email });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <div className="relative font-semibold select-none ">
                  Up-To-Date bleiben
                </div>
              </div>
            </div>
            <div className="absolute block xl:hidden     w-[326.53px] h-[116.29px]">
              <img
                className="absolute top-[58px] left-[340px] w-[103.69px] h-[101.87px] object-cover opacity-[0.4]"
                alt=""
                src="/images/morflaxstudio514@2x.png"
              />
              <img
                className="absolute top-[160.22px] left-[251.5px] w-[158.83px] h-[156.07px] object-cover opacity-[0.6]"
                alt=""
                src="/images/morflaxstudio610@2x.png"
              />
              <img
                className="absolute top-[141px] left-[0px] w-[123.3px] h-[121.15px] object-cover opacity-[0.4]"
                alt=""
                src="/images/morflaxstudio515@2x.png"
              />
              <img
                className="absolute top-[104.97px] left-[349.35px] w-[177.18px] h-[174.08px] object-cover"
                alt=""
                src="/images/morflaxstudio516@2x.png"
              />
              <img
                className="absolute top-[148px] left-[127px] w-[153.37px] h-[153.37px] object-cover opacity-[0.4]"
                alt=""
                src="/images/morflaxstudio517@2x.png"
              />
              <img
                className="absolute top-[18.03px] left-[39.43px] w-[207.72px] h-[204.21px] object-cover opacity-[0.6]"
                alt=""
                src="/images/morflaxstudio76@2x.png"
              />
              <img
                className="absolute top-[0px] left-[172px] w-[216.37px] h-[216.38px] object-cover opacity-[0.6]"
                alt=""
                src="/images/morflaxstudio611@2x.png"
              />
            </div>
          </div>
        </div>
        <div className=" mt-[14rem] mb-20" id="pricing">
          <h1 className="font-bold text-center sm:text-primary text-responsiveHeading ">
            Preise
          </h1>
          <h2 className="text-xl text-center text-[#868686] my-10 font-semibold">
            Wähle Deine Laufzeit
          </h2>
          <div className="flex justify-center ">
            <div className="flex rounded-[15px] relative border-[1px] border-[#D8D8D8] transition-all">
              <div
                onClick={() => setTab("month")}
                className={`${
                  tab === "month" ? "bg-[#001D47]" : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-s-[15px] duration-300  transition-all `}
              >
                1 Monat
              </div>
              <div
                onClick={() => setTab("sixMonths")}
                className={`${
                  tab === "sixMonths"
                    ? "bg-[#001D47]"
                    : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4  duration-300 transition-all`}
              >
                6 Monate
              </div>
              <div
                onClick={() => setTab("twelveMonths")}
                className={`${
                  tab === "twelveMonths"
                    ? "bg-[#001D47]"
                    : "bg-[#fafafa] !text-black"
                } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-e-[15px]  duration-300 transition-all`}
              >
                12 Monate
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex secondary:w-[80%]  secondary:flex-nowrap flex-wrap m-auto secondary:gap-10 gap-[1rem] secondary:p-0  xl:px-[15rem] primary:px-20 justify-center sm:mt-[120px]"
        id="pricing"
      >
        <div className="rounded-[20px]  w-[320px]  p-[35px] bg-[#0084F7] text-[#fff]">
          <h1 className="sm:text-[28px] text-xl font-semibold text-white">
            Standard
          </h1>
          <p className="my-5 font-semibold ">
            <span className="font-bold text-white sm:text-primary text-responsiveHeading">
              {prices[tab]}€
            </span>{" "}
            <br />
            Monat zzgl. MwSt
            {/* 1069€ / Jahr */}
          </p>
          <hr className="h-[1px] bg-[#fafafa] text-[#fafafa]" />
          <h1 className="text-[20px] font-semibold my-5">Features</h1>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            kostenloses Onboarding
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Skriptvorlagen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            VideoWorkbook
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Landingpage Vorlagen
          </p>

          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Statistiken
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Selektionen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Leistungsformel
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Nachrichtensystem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Prämiensystem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Benachrichtigungssytem
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Automatisierungen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Nachrichtenvorlagen
          </p>
          <p className="flex text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Kampagnen Basic
          </p>
        </div>

        <div className="rounded-[20px] relative  w-[320px] sm:my-0 my-20  p-[35px] bg-[#001D47] text-white ">
          {/* <img
            src="/images/premium.png"
            alt=""
            className="sm:hidden block absolute sm:top-[-100px] top-[-50px]  right-[-20px]  "
          /> */}

          <h1 className="sm:text-[28px] text-xl font-semibold">Coaching</h1>
          <p className="text-[#868686] font-semibold text-white my-5">
            <span className="font-semibold text-responsiveHeading">
              Individual
            </span>{" "}
          </p>
          <hr
            className="h-[1px] bg-[#fafafa] text-[#fafafa]"
            style={{ marginTop: 67 }}
          />
          <h1 className="text-[20px] font-semibold my-5">Features</h1>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            1:1 Coaching
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Videokurs deluxe
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            wöchentliche LiveCalls
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            VideoWorkshop
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Drehbuchsupport
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Individual Landingpages
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Skripte & Vorlagen
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Verkauf
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Kundenbroschüre
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Bestandsumsetzung
          </p>
          <p className="flex text-[#fafafa] text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Expertenstatus
          </p>
        </div>

        <div className="rounded-[20px]  w-[320px]  p-[35px] bg-[#fafafa]">
          <h1 className="sm:text-[28px] text-xl font-semibold">Enterprise</h1>
          <p className="my-5 font-semibold">
            <span className="font-semibold text-responsiveHeading">
              Preis auf Anfrage
            </span>{" "}
            <br />
            (Nur für Unternehmen und Gruppen)
          </p>
          <hr className="h-[1px]" />
          <h1 className="text-[20px]  font-semibold my-5">Features</h1>
          <p className="flex text-[#00DEF7] text-sm sm:text-[16px] font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Standard +
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            internes Onboarding
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠interne LiveCalls
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠internes Coaching
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠1:1 Coachings
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            ⁠digitalisierte Arbeitsprozesse
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Videoworkshops
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Firmenlogo
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            CI Skripe & Vorlagen
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Leads
          </p>
          <p className="flex  text-sm sm:text-[16px] py-1 font-semibold items-start gap-2">
            <AiOutlineCheckCircle className="mt-1" />
            Teams
          </p>
        </div>
      </div>
    </div>
  );
};

export default Index;
