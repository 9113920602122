import React, {
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import Box from "../Layout/Box";
import SwitchTitle from "../shared/SwitchTitle";
import Inputs from "../../shared/Input";
import { useMessageContext } from "../../../../context/messageContext";
import { urlRegex } from "../../../../utils/validation";

const CalendlyConfig = forwardRef(
  ({ landingPageData: { calendlyLink, activateCalendly } }, ref) => {
    const { showMessage } = useMessageContext();
    const [calendlyLinkState, setCalendlyLinkState] = useState(calendlyLink);
    const [activateCalendlyState, setActivateCalendlyState] = useState(activateCalendly);


    useImperativeHandle(ref, () => ({
      async save() {
        console.log("CalendlyConfig.save()");

        const deactivate = !urlRegex.test(calendlyLinkState) && activateCalendlyState;
        
        if (deactivate) {
          showMessage(
            "error",
            "Kalenderlink deaktiviert: Bitte gültigen Link eingeben. (https://)"
          );
          setActivateCalendlyState(false);
        }

        return {
          landingPageData: {
            calendlyLink: calendlyLinkState,
            activateCalendly: deactivate ? false : activateCalendlyState,
          },
        };
      },
    }));

    return (
      <Box>
        <SwitchTitle
          checked={activateCalendlyState}
          onChange={(e) => setActivateCalendlyState(e)}
        >
          Kalender-Link
        </SwitchTitle>
        <Inputs
          value={calendlyLinkState}
          onChange={(e) => setCalendlyLinkState(e.target.value)}
          label={"Kalender-Link"}
          emptyCheck={activateCalendlyState}
        />
      </Box>
    );
  }
);

export default CalendlyConfig;
