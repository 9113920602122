import React from "react";

const Video = ({ url, className, textWhite = false, thumbnail, type, heading, textBackground = false }) => {

  const withBackground = (set) => {
    return set ? "bg-black p-1 rounded-sm bg-opacity-50" : ""
  }

  
  return (
    <div
      className={`${className} mx-auto text-center video-container xs:w-[500px] primary:w-[600px] secondary:w-[900px] ${
        textWhite ? "text-white" : "text-black"
      }`}
    >
      <p className={`mb-2 text-xl font-bold secondary:text-4xl secondary:mb-8 ${textBackground ? withBackground(heading != "") : ""}`}>
        {heading}
      </p>
      <video
        playsInline
        poster={thumbnail}
        width="600"
        controls
        className={`aspect-video w-[400px] ${
          type != "stormgas" && "rounded-sm"
        } w-full object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
      >
        <source src={url} type="video/mp4" />
        <source src={url} type="video/webm" />
        <source src={url} type="video/quicktime" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Video;
