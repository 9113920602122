import React, { useState } from "react";
import CollapseItem from "./CollapseItem";
const data = [
  {
    title: "Empfehlungsmarketing was wirklich funktioniert",

    desc: "Erhalte jede Woche passiv neue Empfehlungen mit unserer Software. Deine Kunden werden Dich deutlich öfter und deutlich lieber weiterempfehlen als Du es gewohnt bist.",
  },
  {
    title: "Alle Deine Statistiken",
    desc: "Zufall war gestern! EinGuterTipp hilft Dir Deine eigene Performance und die Deiner Promoter genau zu kennen. So weisst Du immer was es zu tun gilt.",
  },
  {
    title: "100% DSGVO konform",
    desc: "Alle Deine Daten sind sicher und zu 100% ausschließlich auf Europäischen Servern gespeichert. Zudem kommen die Empfehlungen künftig zu Dir. So kannst auch Du Dich nicht (mehr) falsch verhalten.",
  },
  {
    title: "Alles an einem Ort",
    desc: "Ein Platz für Empfehlungen, Promo-Kunden, Verwalten, Bearbeiten, Notizen, Nachrichten, Erinnerungen, Statistiken, Belohnungen.",
  },
  {
    title: "voller Zugriff",
    desc: "Ob im Büro, zu Hause oder unterwegs... EinGuterTipp steht Dir immer und Überall und auf All Deinen Geräten zur Verfügung.",
  },
  {
    title: "Einfache und intuitive Bedienung",
    desc: "Wir haben darauf geachtet, dass sich Jeder schnellstens zurecht findet und es stets einfach und logisch bleibt. Du musst Dich also nicht wochenlang in neue Software reindenken.",
  },
  {
    title: "Selektionen",
    desc: "Lasse Dir mit einem Klick anzeigen was Du gerade brauchst. Wir unterstützen Dich dabei, dass Du stets genau weisst, was noch zu tun ist.",
  },
  {
    title: "Deine individuelle Leistungsformel",
    desc: "Die KI denkt mit und erfasst messbare Aktivitäten. So entsteht Deine Individuelle Leistungsformel. Sie beinhaltet die wichtigsten Kennzahlen Deines Unternehmens. Mit ihr weisst Du jetzt genau was für einen Neukunden zu tun ist. Sie ist ein mächtiges Tool!",
  },
  {
    title: "Erinnerungen und Benachrichtigungen",
    desc: "EinGuterTipp denkt stets mit. So geht Dir nichts mehr verloren.",
  },
  {
    title: "Clevere Automatisierungen",
    desc: "Wo wir können, nehmen wir Dir Arbeit ab, so dass Du Dich maximal auf die wichtigen Dinge konzentrieren kannst.",
  },
  {
    title: "Prämiensystem",
    desc: "Deine Promoter erhalten automatische Belohnungen, die sie als Gutscheine abrufen können. Ob Amazon, Zalando & Co. - wir haben fast Alles.",
  },
  {
    title: "Zentrales Nachrichtensystem",
    desc: "Hierüber bleibst Du mit Deinen Promotern in Kontakt. So kannst Du zb. gezielt oder gesammelt kleine Kampagnen oder Aktionen steuern.",
  },
  {
    title: "WhatsApp integration",
    desc: "Mit EinGuterTipp kannst du WhatsApp genau dort nutzen, wo es am effektivsten ist: In der Kommunikation mit Menschen. Mach es einfach einfach!",
  },
  {
    title: "Kampagnen deluxe (bald)",
    desc: "Hier wirst Du zentral komplexe Kampagnen mit Deinen Kunden umsetzen können.",
  },
  {
    title: "Umsatz- und Einkommensplaner (bald)",
    desc: "EinGuterTipp wird Dich so gut kennen, dass Du Dein Einkommen planen lassen kannst. Gib ein Monats Wunscheinkommen an und Du bekommst auf Basis Deiner Kennzahlen eine persönliche TODO Anleitung, die Dich ans Ziel führt.",
  },
  {
    title: "Aﬁlliate Programm (bald)",
    desc: "Du wirst belohnt für jedes neue Mitglied, welches aufgrund Deiner Empfehlung zu uns kommt.",
  },
  {
    title: "Bestandsumsätze (bald)",
    desc: "Sicherlich wird es auch Umsätze außerhalb des Empfehlungsmarketing geben. Du kannst sie mit einem Klick erfassen - So wird Deine Leistungsformel noch präziser (Leistungsformel deluxe).",
  },
  {
    title: "Leistungsformel deluxe (bald)",
    desc: "Jetzt interpretiert die Software Deine Kennzahlen selbst, zeigt Dir sofort Schieﬂachen und liefert Dir die zugehörigen Lösungsansätze.",
  },
  {
    title: "Leads (bald)",
    desc: "EinGuterTipp wird auch Deine Leads aus Deinem Onlinemarketing aufnehmen und verarbeiten können. Dazu wird es eine speziell eigene Leistungsformel sowie Statistik geben.",
  },
];
const Index = () => {
  const [selected, setSelected] = useState({
    title: "Empfehlungsmarketing was wirklich funktioniert",

    desc: "Erhalte jede Woche passiv neue Empfehlungen mit unserer Software. Deine Kunden werden Dich deutlich öfter und deutlich lieber weiterempfehlen als Du es gewohnt bist.",
  });
  const [open, setOpen] = useState(-1);
  return (
    <div className=" mx-auto my-20   sm:w-[90%]  w-[80%] ">
      <div className="people-to-people-card  py-14 my-10  mx-auto    text-center  ">
        <h1 className="lg:text-primary text-responsiveHeading  font-bold text-white">
          Wir retten etwas wertvolles:
        </h1>
        <h1 className="lg:text-primary  text-responsiveHeading font-bold text-white p-1">
          Das weiterempfehlen von Mensch zu Mensch
        </h1>
        <button className="rounded-[50px] mt-10   text-white font-semibold bg-[#001D47] py-[20px] px-[35px]">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.eingutertipp.de/register"
          >
            JETZT MITMACHEN
          </a>
        </button>
        {/* <Alert
          className="mt-4"
          style={{ marginInline: "2rem" }}
          type="info"
          message={
            <>
              <div>
                Unser Launchtermin steht noch bevor. Deshalb sind aktuell die
                meistenFunktionen deaktiviert. Buche hier einen Telefontermin,
                sofern Du noch Fragen hast. Voraussetzung ist, dass Du Dir die
                Live-Demo angesehen hast.
              </div>

              <a href="https://calendly.com/saschaemmerich/egt-startergesprach">
                Jetzt Demo buchen
              </a>
            </>
          }
        /> */}
      </div>
      <div className="mt-[7rem]">
        <h1 className="sm:text-primary text-responsiveHeading sm:text-start text-center font-bold">
          <span className="text-trkis">Vorteile</span> auf einen Blick
        </h1>

        <div className="flex secondary:flex-row flex-col gap-20  my-10">
          <div className="secondary:w-[60%] w-full people-people-shadow-white ">
            <div className="secondary:w-[80%]  max-h-[500px] overflow-auto tertiary:w-full w-full rounded-[30px]  p-10 people-people-shadow">
              {data.map((e, i) => (
                <div key={i} className="cursor-pointer">
                  <div
                    className="secondary:flex justify-between  items-center border-b transition-all border-[#d8d8d8] py-3 hidden   "
                    onClick={() => setSelected(e)}
                    onMouseDown={(e) => e.target.classList.add("opacity-60")}
                    onMouseUp={(e) => e.target.classList.remove("opacity-60")}
                  >
                    <h1
                      className={`font-semibold sm:text-[1.25rem] text-base   ${
                        e.title === selected.title && "   text-trkis"
                      }`}
                    >
                      {e.title}
                    </h1>
                    <img src="/images/icons8pfeil-ausklappen3-1.png" alt="" />
                  </div>
                  <CollapseItem
                    title={e.title}
                    desc={e?.desc}
                    key={i}
                    open={open === i}
                    setOpen={setOpen}
                    index={i}
                  />
                </div>
              ))}
            </div>
            <div className="white-overlay secondary:!w-[80%] " />
          </div>
          <div className="secondary:w-[40%] secondary:flex hidden w-full  justify-center items-center">
            <div>
              <h3 className="md:text-[20px] text-base font-[600]  animate-fade transition-all">
                {selected.title}
              </h3>
              <p className="mt-10 animate-fade transition-all text-[#868686] text-sm sm:text-[16px] font-[600]">
                {selected.desc}
              </p>
              <button className=" my-10   text-[20px] font-[600] text-white rounded-[50px] px-[35px] py-[20px] bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000]">
                <a href="/erstgesprach">Kostenloses Erstgespräch</a>
              </button>

              {/* <Alert
                className="mt-4"
                type="info"
                message={
                  <>
                    <div>
                      Unser Launchtermin steht noch bevor. Deshalb sind aktuell
                      die meistenFunktionen deaktiviert. Buche hier einen
                      Telefontermin, sofern Du noch Fragen hast. Voraussetzung
                      ist, dass Du Dir die Live-Demo angesehen hast.
                    </div>

                    <a href="https://calendly.com/saschaemmerich/egt-startergesprach">
                      Jetzt Demo buchen
                    </a>
                  </>
                }
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="people-to-people-card-second  py-14 my-10  m-auto    text-center  ">
        <h1 className="text-center  font-bold text-white s xl:text-[46px] text-responsiveHeading lg:text-[38px] md:text-[32px] p-1  my-[3rem] lg:my-[5rem]">
          Return on Investment ab einer Empfehlung
        </h1>
      </div>
    </div>
  );
};

export default Index;
