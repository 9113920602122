import React, { forwardRef, useImperativeHandle, useReducer } from "react";
import Box from "../Layout/Box";
import CollapseItem from "../shared/CollapseItem";
import { stripObjectKeep } from "../../../../utils/StripObject";
import Inputs from "../../shared/Input";
import ClipboardButton from "../../../shared/ClipboardButton";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";

const TextConfig = forwardRef(({ landingPageData }, ref) => {
  const [inputs, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET": {
        return action.payload.data;
      }
      case "SET_KEY": {
        return { ...state, [action.payload.key]: action.payload.value };
      }
    }
  }, stripObjectKeep(landingPageData, ["questionaireIntroductionText", "questionaireButtonText", "aufgrundDeinerAngaben", "negativeText", "inMaximal15Minuten", "vielenDankfurInteresse", "datenSchutzText", "whatsappButtonTextOne", "whatsappButtonTextTwo", "localReviewsHeader", "introVideoHeader"]));

  useImperativeHandle(ref, () => ({
    async save() {
      console.log("textConfig.save()");
      return { landingPageData: inputs };
    },
  }));

  return (
    <Box>
      <CollapseItem
        title={`Passe die Texte Deiner Landingpage nach Deinen Bedürfnissen an`}
      >
        <div className="flex flex-col gap-5 mt-5">
          <Inputs
            value={inputs.whatsappButtonTextOne}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "whatsappButtonTextOne",
                },
              })
            }
            label="Erster WhatsApp-Button"
          />
          <Inputs
            value={inputs.localReviewsHeader}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "localReviewsHeader",
                },
              })
            }
            label="Überschrift Testimonials"
          />
          <ClipboardButtonsBar names={["Videolaenge"]} />
          <Inputs
            value={inputs.introVideoHeader}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "introVideoHeader",
                },
              })
            }
            label="Überschrift Präsentationsvideo"
          />
          <Inputs
            value={inputs.whatsappButtonTextTwo}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "whatsappButtonTextTwo",
                },
              })
            }
            label="Zweiter WhatsApp-Button"
          />
          <Inputs
            value={inputs.questionaireIntroductionText}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "questionaireIntroductionText",
                },
              })
            }
            label="Frageeinleitungstext"
          />
          <Inputs
            value={inputs.questionaireButtonText}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "questionaireButtonText",
                },
              })
            }
            label="Buttontext"
          />
          <Inputs
            value={inputs.aufgrundDeinerAngaben}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "aufgrundDeinerAngaben",
                },
              })
            }
            label="Positivtext"
          />
          <Inputs
            value={inputs.negativeText}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: { value: e.target.value, key: "negativeText" },
              })
            }
            label="Negativtext"
          />
          <Inputs
            value={inputs.datenSchutzText}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: { value: e.target.value, key: "datenSchutzText" },
              })
            }
            label="Datenschutztext"
          />
          <Inputs
            value={inputs.vielenDankfurInteresse}
            onChange={(e) =>
              dispatch({
                type: "SET_KEY",
                payload: {
                  value: e.target.value,
                  key: "vielenDankfurInteresse",
                },
              })
            }
            label="Danketext"
          />
        </div>
      </CollapseItem>
    </Box>
  );
});

export default TextConfig;
