// src/KontoEditPage.js
import {
  Avatar,
  Button,
  Checkbox,
  message,
  Modal,
  Radio,
  Skeleton,
  Space,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { login } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import SubscriptionService from "../../service/SubscriptionService";
import UploadToCloudService from "../../service/UploadToCloudService";
import { default as UserService } from "../../service/UserService";
import { PopUpComponent } from "../ConfirmPopup";
import { default as CollapseItem } from "../Dashboard/kampaign/CollapseItem";
import { fields } from "../Einstellungen/Data";
import HTMLTextEditor from "../Einstellungen/HtmlEditor";
import { default as FloatLabel } from "../FloatingLabel";
import useWindowWidth from "../Home/MakeYouFit/useWindowWidth";

const editors = {
  // defaultPrivacyText: "Datenschutz-Text",
  // defaultPrivacyLink: "Datenschutz-Link",
  defaultImpressumText: "Impressum-Text",
  defaultImpressumLink: "Impressum-Link",
};

const KontoEditPage = ({ title }) => {
  const [userobj, setUserObj] = useState(null);
  const { user: savedUser } = useSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const navigate = useNavigate();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [kundigungModal, setKundigungModal] = useState(false);
  const [popupComponent, setPopupComponent] = useState(false);
  const [checkboxTarifWechselValue, setCheckboxTarifWechselValue] =
    useState(false);
  useEffect(() => {
    if (!popupComponent) setCheckboxTarifWechselValue(false);
  }, [popupComponent]);

  const width = useWindowWidth();

  React.useEffect(() => {
    const getSubscriptionInfo = async () => {
      const { data } = await SubscriptionService.getSubscriptionInfo();
      setSubscription(data?.subscription);
    };
    getSubscriptionInfo();
  }, []);

  const [password, setPassword] = useState({
    password: {
      show: false,
      text: "",
    },
    confirmpassword: {
      show: false,
      text: "",
    },
  });
  const [newMail, setNewMail] = useState({});
  const [user, setUser] = useState({});
  const proofEmail = () => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!newMail.newmail || !newMail.newmailconfirm) {
      message.error("Bitte fülle beide Felder aus");
      return false;
    }
    if (newMail.newmail !== newMail.newmailconfirm) {
      message.error("Email Adressen stimmen nicht überein");
      return false;
    }

    if (!validRegex.test(newMail.newmail)) {
      message.error("Geben Sie eine gültige E-Mail-Adresse ein");
      return false;
    }
    return true;
  };

  const handleNewEmailChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setNewMail((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const submitEmailChange = async () => {
    try {
      await UserService.updateEmail({
        email: newMail?.newmail,
        baseUrl: window.origin,
        oldMail: user?.email,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Neu Laden nach Email Änderung
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "emailVerified" && event.newValue === "true") {
        window.location.reload();
      }
      localStorage.removeItem("emailVerified");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const { data } = await UserService.me();
        setUserObj(data?.user);
        setUser((prev) => ({ ...prev, ...data?.user }));
        store.dispatch(login(data));

        title("Konto");
      } catch (error) {}
    };
    getUserObj();
  }, [title]);

  const handlePasswordUIChange = (key, subkey, value) => {
    setPassword((prev) => ({
      ...prev,
      [key]: { ...prev[key], [subkey]: value },
    }));
  };

  const onFinish = async (e) => {
    e?.preventDefault?.();
    const finalUser = { ...user };
    try {
      await UserService.updateUserInfo({ ...finalUser });
      const { data: me } = await UserService.me();
      store.dispatch(login(me));
      message.success("Aktualisiert");
    } catch (error) {
      console.log(error);
    }
  };
  const handleAvatarChange = async (info) => {
    setLoading(true);
    const imageObj = info.file.originFileObj;
    const finalUser = { ...user };
    try {
      const info = await UploadToCloudService.upload(imageObj);
      const url = info.secure_url;
      delete finalUser?.imageObj;
      finalUser.avatar = url;
      await UserService.updateUserInfo({ ...finalUser });
      const { data: me } = await UserService.me();
      setUser(me.user);
      store.dispatch(login(me));
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setUser({
      ...user,
      [key]: value,
    });
    setUserObj({
      ...user,
      [key]: value,
    });
  };

  const handleToggleAutoVerlangerung = useCallback(
    async (newValue) => {
      const canToggle =
        user?.renewalInterval === 1 ||
        !moment(new Date(subscription.cancel_at * 1000)).isBefore(
          moment().add(1, "months")
        );
      if (process.env.NODE_ENV !== "development" && !canToggle)
        return message.info(
          "Die automatische Verlängerung kann nicht ausgeschaltet werden, da die Kündigungsfrist 1 Monat zum Laufzeitende beträgt."
        );

      if (!newValue) {
        setCheckboxValue(false);
        setKundigungModal(true);

        return;
      }

      if (newValue) {
        message.success("Automatische Verlängerung wurde eingeschaltet");
      } else {
        message.success("Automatische Verlängerung wurde ausgeschaltet");
      }

      await UserService.updateUserInfo({ autoRenewals: newValue }).then(
        async () => {
          const res = await UserService.me();
          setUserObj(res?.data?.user);

          const res2 = await SubscriptionService.getSubscriptionInfo();
          setSubscription(res2?.data?.subscription);
        }
      );
    },
    [subscription, user]
  );

  const handleConfirmCancel = useCallback(async () => {
    if (!checkboxValue) return message.info("Bitte kreuze das Kästchen an");

    setUserObj((u) => ({ ...u, autoRenewals: false }));
    message.success("Automatische Verlängerung wurde ausgeschaltet");

    await UserService.updateUserInfo({ autoRenewals: false });
  }, [checkboxValue]);

  if (!userobj) {
    return <Skeleton active />;
  }
  return (
    <div>
      <div className="flex justify-center flex-wrap special-upload">
        <Upload
          onChange={handleAvatarChange}
          disabled={loading}
          thumbUrl={false}
          className="flex-col flex whitespace-normal justify-center items-center my-2 flex-wrap"
        >
          <Avatar
            src={user?.avatar ?? "/images/postausgang-btn.png"}
            className="h-40 w-40 "
          />
        </Upload>
      </div>

      <div className="my-8">
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Persönliche Informationen
            </div>
          }
        >
          <form onSubmit={onFinish}>
            <div className="my-5 flex flex-col gap-8 w-full">
              <div className="flex gap-8 flex-wrap ">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Vorname"}
                >
                  <input
                    type="text"
                    name="firstname"
                    required={savedUser["firstname"]?.length > 0}
                    onChange={handleChange}
                    value={user?.firstname}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Nachname"}
                >
                  <input
                    type="text"
                    name="lastname"
                    required={savedUser["lastname"]?.length > 0}
                    onChange={handleChange}
                    value={user?.lastname}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow"
                  />
                </FloatLabel>
              </div>

              <div className="flex gap-8 flex-wrap flex-grow">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Firma"}
                >
                  <input
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    required={savedUser["companyName"]?.length > 0}
                    value={user?.companyName}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Umsatzsteuernummer / Steuernummer"}
                >
                  <input
                    type="text"
                    name="VAT"
                    required={savedUser["VAT"]?.length > 0}
                    onChange={handleChange}
                    value={user?.VAT}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div className="flex gap-8 flex-wrap flex-grow">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Straße"}
                >
                  <input
                    type="text"
                    name="street"
                    onChange={handleChange}
                    required={savedUser["street"]?.length > 0}
                    value={user?.street}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Hausnummer"}
                >
                  <input
                    name="streetNr"
                    onChange={handleChange}
                    required={savedUser["streetNr"]?.length > 0}
                    value={user?.streetNr}
                    className="border-[1px] w-[100px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto   bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div className="flex gap-8 flex-wrap">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Ort"}
                >
                  <input
                    type="text"
                    name="city"
                    required={savedUser["city"]?.length > 0}
                    onChange={handleChange}
                    value={user?.city}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"PLZ"}
                >
                  <input
                    type="number"
                    name="postCode"
                    required={savedUser["postCode"]?.length > 0}
                    onChange={handleChange}
                    value={user?.postCode}
                    className="border-[1px] w-[100px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto   bg-transparent"
                  />
                </FloatLabel>
              </div>
              <div>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold"
                  className={"flex-grow  "}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Geburtsdatum"}
                >
                  <input
                    type="date"
                    name="dateOfBirth"
                    required={savedUser["dateOfBirth"]?.length > 0}
                    onChange={handleChange}
                    value={user?.dateOfBirth?.split?.("T")[0]}
                    className="border-[1px]  border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px]    bg-transparent"
                  />
                </FloatLabel>
              </div>

              <div className="my-10 w-full ">
                <FloatLabel
                  label={"Branche auswählen"}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={" flex-grow"}
                  keepitfocused
                >
                  <ReactSelect
                    className="border-[1px] border-[#d8d8d8] font-semibold !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                    options={fields}
                    classNamePrefix={"profile-select"}
                    value={{
                      value: userobj?.industry,
                      label: userobj?.industry,
                    }}
                    required={savedUser["industry"]?.length > 0}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          value: e.value,
                          name: "industry",
                        },
                      });
                      onFinish();
                    }}
                    components={{ IndicatorSeparator: "" }}
                  ></ReactSelect>
                </FloatLabel>
              </div>

              <div className="flex gap-8 flex-wrap">
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"E-Mail Adresse "}
                >
                  <input
                    type="text"
                    name="email"
                    required={savedUser["email"]?.length > 0}
                    readOnly
                    value={user?.email}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
                <FloatLabel
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"flex-grow"}
                  focusClass=" text-[#D8D8D8]  ms-[5px]"
                  keepitfocused
                  label={"Telefonnummer"}
                >
                  <input
                    type="text"
                    name="phone"
                    required={savedUser["phone"]?.length > 0}
                    onChange={handleChange}
                    value={user?.phone}
                    className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent"
                  />
                </FloatLabel>
              </div>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
            >
              Speichern
            </Button>
          </form>
        </CollapseItem>
      </div>

      <div className="my-8">
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Alternative Rechnungsadresse
            </div>
          }
        >
          <form onSubmit={onFinish} className="py-2">
            <div className="trkisswitch font-bold text-md md:text-lg">
              Gleiche wie oben{" "}
              <Switch
                checked={user?.invoiceAddressSame}
                onChange={(e) => {
                  setUser((prev) => ({ ...prev, invoiceAddressSame: e }));
                }}
              />
            </div>

            {!user?.invoiceAddressSame && (
              <div className="my-5 flex flex-col gap-8 w-full">
                <div className="flex gap-8 flex-wrap">
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Straße"}
                  >
                    <input
                      type="text"
                      name="invoiceStreet"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceStreet}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Hausnummer"}
                  >
                    <input
                      type="text"
                      name="invoiceStreetNr"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceStreetNr}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent flex-grow",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                </div>

                <div className="flex gap-8 flex-wrap flex-grow">
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"Ort "}
                  >
                    <input
                      type="text"
                      name="invoicePostCode"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoicePostCode}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                  <FloatLabel
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"flex-grow"}
                    focusClass=" text-[#D8D8D8]  ms-[5px]"
                    keepitfocused
                    label={"PLZ"}
                  >
                    <input
                      type="text"
                      name="invoiceCity"
                      onChange={handleChange}
                      disabled={user?.invoiceAddressSame}
                      value={user?.invoiceCity}
                      className={classNames(
                        "border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full  bg-transparent",
                        {
                          "cursor-not-allowed": user?.invoiceAddressSame,
                        }
                      )}
                    />
                  </FloatLabel>
                </div>
              </div>
            )}

            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
            >
              Speichern
            </Button>
          </form>
        </CollapseItem>
      </div>
      <div>
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Email Adresse ändern
            </div>
          }
        >
          <form method="POST" className="my-5 flex flex-col gap-8 w-full">
            <div className="flex gap-8 flex-wrap ">
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"neue Email"}
              >
                <input
                  required
                  type={"email"}
                  value={newMail?.newmail}
                  name="newmail"
                  onChange={handleNewEmailChange}
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
              </FloatLabel>
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"Erneut neue Email"}
              >
                <input
                  required
                  type={"email"}
                  value={newMail?.newmailconfirm}
                  name="newmailconfirm"
                  onChange={handleNewEmailChange}
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
              </FloatLabel>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              onClick={(e) => {
                e.preventDefault();
                if (proofEmail()) {
                  return PopUpComponent({
                    onConfirm: submitEmailChange,
                    heading: "Änderung Email Adresse",
                    subheading:
                      "Mit dieser Änderung wird deine Email Adresse vollständig ersetzt! Die neue Email Adresse wird mit der Änderung ab sofort sowohl für die Anmeldung bei EinGuterTipp, sowie jeglichen Schriftwechsel, als auch rund um das Thema Zahlungen und Abrechnungen genutzt.",
                    confirmButtonText: "Bestätigen",
                  });
                }
              }}
            >
              Ändern
            </Button>
          </form>
        </CollapseItem>
      </div>
      <div>
        <CollapseItem
          defaultOpen={true}
          title={
            <div className="text-md md:text-xl font-semibold">
              Passwort ändern
            </div>
          }
        >
          <form method="POST" className="my-5 flex flex-col gap-8 w-full">
            <div className="flex gap-8 flex-wrap ">
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"neues Passwort"}
              >
                <input
                  required
                  type={password.password.show ? "text" : "password"}
                  value={password.password.text}
                  name="password"
                  onChange={(e) =>
                    handlePasswordUIChange("password", "text", e.target.value)
                  }
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
                {password.password.show ? (
                  <AiOutlineEyeInvisible
                    onClick={() =>
                      handlePasswordUIChange(
                        "password",
                        "show",
                        !password.password.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() =>
                      handlePasswordUIChange(
                        "password",
                        "show",
                        !password.password.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                )}
              </FloatLabel>
              <FloatLabel
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                className={
                  "flex items-center border-[#d6d6d6] rounded-[10px] border-[1px] px-2 flex-grow"
                }
                focusClass=" text-[#D8D8D8]  ms-[5px]"
                keepitfocused
                label={"altes Passwort eingeben"}
              >
                <input
                  required
                  type={password.confirmpassword.show ? "text" : "password"}
                  value={password.confirmpassword.text}
                  name="confirmpassword"
                  onChange={(e) =>
                    handlePasswordUIChange(
                      "confirmpassword",
                      "text",
                      e.target.value
                    )
                  }
                  className=" w-full text-[20px] font-semibold  p-3 outline-none bg-transparent  "
                />
                {password.confirmpassword.show ? (
                  <AiOutlineEyeInvisible
                    onClick={() =>
                      handlePasswordUIChange(
                        "confirmpassword",
                        "show",
                        !password.confirmpassword.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                ) : (
                  <AiOutlineEye
                    onClick={() =>
                      handlePasswordUIChange(
                        "confirmpassword",
                        "show",
                        !password.confirmpassword.show
                      )
                    }
                    className="font-semibold cursor-pointer  !text-[20px]"
                  />
                )}
              </FloatLabel>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className="h-[60px] text-md md:text-xl font-semibold w-full my-5"
              onClick={(e) => {
                e.preventDefault();
                UserService.changePassword({
                  newPassword: password.password.text,
                  oldPassword: password.confirmpassword.text,
                });
              }}
            >
              Ändern
            </Button>
          </form>
        </CollapseItem>
      </div>

      <CollapseItem
        title={
          <div className="text-md md:text-xl font-semibold">Rechtliches</div>
        }
        defaultOpen={true}
      >
        <div className="p-8 flex flex-col gap-8">
          {Object.keys(editors).map((e, i) => {
            const isEditor = editors[e].split("-")[1] === "Text";
            return (
              <div className="flex flex-col gap-8">
                {!isEditor ? (
                  <>
                    <FloatLabel
                      label={editors[e]}
                      unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                      className={"xl:flex-grow-0 flex-grow"}
                      focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
                      keepitfocused
                      key={i}
                    >
                      <input
                        type="text"
                        className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                        name={e}
                        value={userobj[e]}
                        onChange={handleChange}
                      />
                    </FloatLabel>
                    {e === "defaultImpressumLink" && (
                      <div className="mb-5">
                        <a
                          href="https://www.e-recht24.de/impressum-generator.html"
                          target="_blank"
                        >
                          Wenn Du noch kein Impressum hast, kannst du es mit
                          eRecht24 problemlos selbst erstellen.
                        </a>
                      </div>
                    )}
                    {e === "defaultPrivacyLink" && (
                      <div className="mb-5">
                        <a
                          href="https://www.e-recht24.de/muster-datenschutzerklaerung.html"
                          target="_blank"
                        >
                          Wenn Du noch kein Datenschutz hast, kannst du es mit
                          eRecht24 problemlos selbst erstellen.
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <HTMLTextEditor
                      label={editors[e]}
                      value={userobj[e]}
                      onChange={(text) => {
                        handleChange({
                          target: {
                            name: e,
                            value: text,
                          },
                        });
                      }}
                      key={i}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onFinish}
          className="h-[60px] text-md md:text-xl font-semibold w-full mb-5"
        >
          Speichern
        </Button>
      </CollapseItem>

      {subscription?.id ? (
        <div
          className="text-white p-5 rounded-11xl shadow-md mt-10"
          style={{ background: "#0084F7" }}
        >
          <div className="flex justify-between items-center mb-5">
            <h2 className="text-md md:text-xl font-semibold">Standard</h2>
            <p className="text-md md:text-lg">
              {(subscription?.plan?.amount / 100).toFixed(2)}€ /{" "}
              {subscription?.plan?.interval_count < 45 ? "Monat" : "Jahr"}{" "}
              (zzgl. MwSt.)
            </p>
          </div>

          <div className="flex justify-between items-center mb-5">
            <h2 className="text-md md:text-xl font-semibold">
              {subscription?.cancel_at_period_end
                ? "Ablaufdatum"
                : "Nächste Zahlung"}
            </h2>
            <p className="text-md md:text-lg">
              {moment(subscription?.current_period_end * 1000).format(
                "Do MMM, YYYY"
              )}{" "}
              {subscription?.cancel_at_period_end
                ? "(wird nicht verlängert)"
                : ""}
            </p>
          </div>

          <div className="flex justify-between items-center mb-5">
            <Space>
              <h2 className="text-md md:text-xl font-semibold">
                Automatische Verlängerung
              </h2>
              <Tooltip
                placement="left"
                title={
                  <>
                    <div>
                      Aktiviert: Dein Abo verlängert sich automatisch um den
                      gewählten Laufzeittarif
                    </div>
                    <div>
                      Deaktiviert: Dein Abo endet zum Ablauf des aktuellen oder
                      -wenn gewählt- des Anschlußtarifs
                    </div>
                  </>
                }
              >
                <IoIosInformationCircleOutline color="#001D47" size={22} />
              </Tooltip>
            </Space>

            <Switch
              checked={userobj?.autoRenewals}
              onChange={handleToggleAutoVerlangerung}
            />
          </div>

          <div className="flex justify-between items-center mb-5">
            <Space>
              <h2 className="text-md md:text-xl font-semibold">
                Laufzeitauswahl
              </h2>
              <Tooltip
                placement="left"
                title={
                  <>
                    <div>Blau: Aktueller Tarif</div>
                    <div>
                      Türkis: Zeigt den von dir ausgewählten Anschlußtarif
                    </div>
                  </>
                }
              >
                <IoIosInformationCircleOutline color="#001D47" size={22} />
              </Tooltip>
            </Space>

            <Radio.Group
              size="small"
              dire
              options={[
                {
                  label: width < 568 ? "1M" : "1 Monat",
                  value: 1,
                  style:
                    userobj?.subscription?.futurePlan?.renewalInterval === 1
                      ? {
                          background: "#00FDFF",
                          borderColor: "#00FDFF",
                          color: "black",
                        }
                      : userobj?.subscription?.futurePlan &&
                        userobj.renewalInterval === 1
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : userobj.renewalInterval === 1
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : {},
                },
                {
                  label: width < 568 ? "6M" : "6 Monate",
                  value: 6,
                  style:
                    userobj?.subscription?.futurePlan?.renewalInterval === 6
                      ? {
                          background: "#00FDFF",
                          borderColor: "#00FDFF",
                          color: "black",
                        }
                      : userobj?.subscription?.futurePlan &&
                        userobj.renewalInterval === 6
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : userobj.renewalInterval === 6
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : {},
                },
                {
                  label: width < 568 ? "12M" : "12 Monate",
                  value: 12,
                  style:
                    userobj?.subscription?.futurePlan?.renewalInterval === 12
                      ? {
                          background: "#00FDFF",
                          borderColor: "#00FDFF",
                          color: "black",
                        }
                      : userobj?.subscription?.futurePlan &&
                        userobj.renewalInterval === 12
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : userobj.renewalInterval === 12
                      ? {
                          background: "#001D47",
                          borderColor: "#001D47",
                          color: "white",
                        }
                      : {},
                },
              ]}
              optionType="button"
              buttonStyle="solid"
              onChange={(e) => {
                const selection = e.target.value;
                if (selection === userobj.renewalInterval) return;

                if (userobj.subscription?.futurePlan)
                  return PopUpComponent({
                    onConfirm: () => {},
                    heading: "Umstellung nicht möglich",
                    subheading:
                      "Du hast bereits ein Anschlußabo ausgewählt. Dieses startet automatisch zur nächsten Beitragszahlung, allerdings frühestens zum Ende Deiner aktuellen Laufzeitperiode. Änderungen sind erst wieder möglich, wenn das gewählte Anschlußabo aktiv ist.",
                    hideButtons: true,
                  });

                if (selection > userobj.renewalInterval) {
                  // in längere Laufzeit umstellen geht sofort
                  setPopupComponent({
                    selection,
                    heading: "TARIFWECHSEL!",
                    subheading: `Du möchtest in einen Tarif mit ${selection} Monat${
                      selection === 1 ? "" : "en"
                    } Laufzeit wechseln. Der Wechsel wird zur Fälligkeit des nächsten Beitrages erfolgen. Ab dann gilt dein neuer Beitrag und die gewählte Laufzeit.`,
                    checkbox:
                      "Ich möchte diesen Tarifwechsel jetzt durchführen",
                  });
                } else {
                  setPopupComponent({
                    selection,
                    heading: "Tarifwechselanfrage",
                    subheading: `Du möchtest in einen Tarif mit kürzerer Laufzeit wechseln? Kontaktiere dazu bitte den Support mit dem Stichwort „Downgrade“ und gibt unbedingt auch deine Rufnummer an, damit wir dich für Details erreichen können.`,
                    checkbox:
                      "Mir ist bewusst, dass ein Wechsel zu einem höheren Abopreis führt.",
                  });
                }
              }}
              value={userobj.renewalInterval}
            />
          </div>

          {subscription?.cancel_at && (
            <div className="flex justify-between items-center mb-5">
              <Space>
                <h2 className="text-md md:text-xl font-semibold">
                  Aktuelles Laufzeitende
                </h2>
              </Space>

              <p className="text-md md:text-lg">
                {moment(subscription?.cancel_at * 1000).format("Do MMM, YYYY")}
              </p>
            </div>
          )}

          <div className="mt-4">
            <button
              className="bg-transparent hover:bg-blue-500 text-white border border-white hover:text-white px-4 py-2 rounded-full w-full"
              onClick={async () => {
                if (!subscription) {
                  navigate("/dashboard/upgrade");

                  return;
                }
                const response =
                  await SubscriptionService.generateSubscriptionManagementLink();
                window.location.href = response.data.link;
              }}
            >
              Abo Verwalten
            </button>
          </div>
        </div>
      ) : (
        <div
          className="text-white p-5 rounded-11xl shadow-md mt-10"
          style={{ background: "#0084F7" }}
        >
          <div className="mt-4">
            <button
              className="bg-transparent hover:bg-blue-500 text-white border border-white hover:text-white px-4 py-2 rounded-full w-full"
              onClick={async () => {
                navigate("/dashboard/upgrade");
              }}
            >
              Jetzt Abonnieren
            </button>
          </div>
        </div>
      )}

      <Modal
        open={kundigungModal}
        onCancel={() => setKundigungModal(false)}
        title="Achtung!"
        footer={[]}
      >
        <div>
          <p className="my-1">
            Du bist dabei deine Lizenz zum Ablauf der aktuellen Laufzeitperiode
            zu kündigen. Bitte bedenke, dass Dir und deinen Kunden ab dann keine
            Funktionen mehr zur Verfügung stehen. Das bedeutet unter Anderem
            z.B.: auch keinen Zugriff mehr auf Daten wie Promoter oder
            Empfehlungen. Wir empfehlen dir für weitere Informationen und
            Optionen erstmal den Support an zu schreiben.
          </p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass ich auf weitere Infos verzichte und mir der
              Folgen bewusst bin
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              setKundigungModal(false);
              await handleConfirmCancel();
            }}
          >
            Abonnement kündigen
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setKundigungModal(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>

      <Modal
        open={popupComponent}
        onCancel={() => setPopupComponent(false)}
        title={popupComponent?.heading}
        footer={[]}
      >
        <div>
          <p className="my-1">{popupComponent?.subheading}</p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxTarifWechselValue}
              onChange={(e) => setCheckboxTarifWechselValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              {popupComponent?.checkbox}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              if (!checkboxTarifWechselValue)
                return message.info("Bitte kreuze das Kästchen an");

              if (popupComponent?.selection < userobj.renewalInterval) {
                return navigate("/dashboard/support");
              }

              setPopupComponent(false);
              setUser({
                ...user,
                renewalInterval: popupComponent?.selection,
              });

              UserService.updateUserInfo({
                renewalInterval: popupComponent?.selection,
              }).then(async () => {
                const res = await UserService.me();
                setUserObj(res?.data?.user);

                const res2 = await SubscriptionService.getSubscriptionInfo();
                setSubscription(res2?.data?.subscription);
              });
            }}
          >
            {popupComponent?.selection < userobj.renewalInterval
              ? "Zum Support"
              : "Bestätigen"}
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setPopupComponent(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default KontoEditPage;
