import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";


const IntroductionToClient = forwardRef(
  ({ landingPageData: { thumbnail, persentationVideo } }, ref) => {
    const [videoSrc, setVideoSrc] = useState(persentationVideo);
    const [thumbnailSrc, setThumbnailSrc] = useState(thumbnail);
    const thumbnailRef = useRef(null);
    const videoRef = useRef(null);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("IntroductionToClient.save()");

        const [newThumbnailUrl, newVideoUrl] = await Promise.all([
          thumbnailRef.current.saveLocalToCloud(),
          videoRef.current.saveLocalToCloud(),
        ]);

        return {
          landingPageData: {
            ...(newThumbnailUrl.success && {
              thumbnail: newThumbnailUrl.secure_url,
            }),
            ...(newVideoUrl.success && {
              presentationVideoDuration: newVideoUrl.duration,
            }),
            ...(newVideoUrl.success && {
              persentationVideo: newVideoUrl.secure_url,
            }),
          },
        };
      },
    }));

    return (
      <Box>
        <h1 className="text-xl font-bold text-center xl:text-primary">
          Stelle dich deinen Kunden in einem kurzen Video vor.
        </h1>

        <p className="my-5 font-semibold text-center text-lightgray ">
          Das ist ein sehr wichtiges Element und wird den Empfehlungen helfen
          sich ein Bild von Dir zu machen.
          <br /> Hier kannst Du als ganze Persönlichkeit überzeugen.
        </p>

        <div className="my-10 sm:w-[80%] w-full m-auto">
          <p className="my-5 text-base font-semibold text-center text-trkis sm:text-xl">
            Worauf es ankommt:
          </p>
          <ul className="grid gap-3 m-auto font-semibold primary:gap-1 hero-list max-w-max">
            <li className="flex items-center">Erkläre was Du anders und besser machst als Andere</li>
            <li className="flex items-center">Halte es mind 60s max 120s kurz</li>
            <li className="flex items-center">Zeig dazu natürlich auch wie sympathisch Du bist!</li>
            <li className="flex items-center">Was man von Dir erwarten kann.</li>
          </ul>
        </div>

        <MediaUpload
          ref={videoRef}
          full={true}
          type="video"
          id="landing-page-config-change-video"
          textSrcNotThere="Video ändern/hochladen"
          textSrcThere="Video ändern/hochladen"
          url={videoSrc}
          setUrl={setVideoSrc}
        ></MediaUpload>

        <div className="text-base font-semibold text-center lg:text-9xl sm: sm:text-xl mb-[30px] mt-10">
          Laden Sie ein ansprechendes Miniaturbild hoch, das zur Darstellung des
          Videos verwendet wird
        </div>

        <MediaUpload
          ref={thumbnailRef}
          full={true}
          id={"upload-UploadTutorials-first"}
          url={thumbnailSrc}
          setUrl={setThumbnailSrc}
          textSrcNotThere={"Miniaturbild ändern"}
          textSrcThere={"Miniaturbild hochladen"}
        />
      </Box>
    );
  }
);

export default IntroductionToClient;
