import { Collapse, message, Radio, Switch } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AdminService from "../../../service/AdminService";
import useWindowWidth from "../../Home/MakeYouFit/useWindowWidth";

const PartnerSubscription = ({ subscriptionUser, userid }) => {
  const [subscription, setSubscription] = useState();
  const [userobj, setUserobj] = useState();
  const [price, setPrice] = useState("");
  const width = useWindowWidth();
  const debounceTimeout = useRef(null); // Ref to store debounce timeout

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      if (!subscriptionUser?.id) return;
      const { data } = await AdminService.getUserSubscriptionInfo(
        subscriptionUser.id
      );
      setSubscription(data?.subscription);
    };
    getSubscriptionInfo();
  }, [subscriptionUser]);

  const fetchUser = useCallback(() => {
    if (userid)
      AdminService.getUserStats(userid).then((res) => {
        setUserobj(res.data.user);
        setPrice(res.data.user.adminSpecifiedSubscriptionPrice || "");
      });
  }, [userid]);
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleToggleAutoVerlangerung = async (newValue) => {
    await AdminService.updateUserDetails({
      user_id: userobj._id,
      data: {
        ...userobj,
        autoRenewals: newValue,
      },
    }).then(async () => {
      fetchUser();
    });

    if (newValue) {
      message.success("Automatische Verlängerung wurde eingeschaltet");
    } else {
      message.success("Automatische Verlängerung wurde ausgeschaltet");
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);

    // Clear the previous timeout if there is one
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to update the user details after 1 second
    debounceTimeout.current = setTimeout(async () => {
      await AdminService.updateUserDetails({
        user_id: userobj._id,
        data: {
          ...userobj,
          adminSpecifiedSubscriptionPrice: value,
        },
      });
    }, 1000); // 1 second delay
  };

  if (!subscription?.id) return <></>;
  if (!userobj) return <></>;
  return (
    <>
      <Collapse
        items={[
          {
            key: "Abonmement",
            label: "Abonmement",
            children: (
              <>
                <div className=" ">
                  <div className="flex justify-between items-center mb-5">
                    <h2 className="">Standard</h2>
                    <p className="">
                      {(subscription?.plan?.amount / 100).toFixed(2)}€ /{" "}
                      {subscription?.plan?.interval_count < 45
                        ? "Monat"
                        : "Jahr"}{" "}
                      (zzgl. MwSt.)
                    </p>
                  </div>

                  <div className="flex justify-between items-center mb-5">
                    <h2 className="">Spezialpreis</h2>
                    <p className="">
                      <input
                        type="number"
                        value={price}
                        onChange={handlePriceChange}
                      />
                      € /{" "}
                      {subscription?.plan?.interval_count < 45
                        ? "Monat"
                        : "Jahr"}{" "}
                      (zzgl. MwSt.)
                    </p>
                  </div>

                  <div className="flex justify-between items-center mb-5">
                    <h2 className=" ">
                      {subscription?.cancel_at_period_end
                        ? "Ablaufdatum"
                        : "Nächste Zahlung"}
                    </h2>
                    <p className="">
                      {moment(subscription?.current_period_end * 1000).format(
                        "Do MMM, YYYY"
                      )}{" "}
                      {subscription?.cancel_at_period_end
                        ? "(wird nicht verlängert)"
                        : ""}
                    </p>
                  </div>

                  <div className="flex justify-between items-center mb-5">
                    <h2 className=" ">Automatische Verlängerung</h2>
                    <Switch
                      checked={userobj?.autoRenewals}
                      onChange={handleToggleAutoVerlangerung}
                    />
                  </div>

                  {subscription?.cancel_at && (
                    <div className="flex justify-between items-center mb-5">
                      <h2 className=" ">Aktuelles Laufzeitende</h2>
                      <p className="">
                        {moment(subscription?.cancel_at * 1000).format(
                          "Do MMM, YYYY"
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </>
            ),
          },
        ]}
        defaultActiveKey={["1"]}
      />
    </>
  );
};

export default PartnerSubscription;
