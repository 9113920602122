import { Tooltip } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Pramienkeys } from "../../../Einstellungen/Data";
import FloatLabel from "../../../FloatingLabel";
import Inputs from "../../shared/Input";
import Box from "../Layout/Box";
import CollapseItem from "../shared/CollapseItem";

const Pramien = forwardRef(
  ({ landingPageData: { pramienConfiguration } }, ref) => {
    const [configState, setImpressumState] = useState(pramienConfiguration);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("Pramien.save()");
        return {
          landingPageData: {
            pramienConfiguration: configState,
          },
        };
      },
    }));

    return (
      <Box>
        <CollapseItem
          title={"Pramien"}
          icon={
            <Tooltip title="Für die Landingpage werden automatisch Deine im Konto hinterlegten Standardprämien genutzt. Hier bitte nur ausfüllen, wenn Du für diese Seite abweichende Prämien hinterlegen möchtest.">
              <span className="border-[1px] border-[#d8d8d8] p-2 rounded-[10px] mx-2">
                <AiOutlineInfoCircle />
              </span>
            </Tooltip>
          }
        >
          <div className="flex flex-col gap-7 mt-5 text-sm">
            {Object.keys(Pramienkeys)
              ?.filter((a) => a !== "mindestEinloseWert")
              ?.map((e, i) => {
                return (
                  <FloatLabel
                    label={Pramienkeys[e]}
                    unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                    className={"xl:flex-grow-0 flex-grow"}
                    keepitfocused
                    key={i}
                  >
                    <input
                      type="text"
                      className="border-[1px] border-[#d8d8d8] !shadow-none font-semibold !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                      value={(configState?.[e] || 0) + " Euro"}
                      onChange={(ce) => {
                        const value = ce.target.value.replace(" Euro", "");
                        const pattern =
                          ce.target.value.replace(" Euro", "").length > 0
                            ? /^[0-9]+$/g
                            : null;

                        if (pattern && !pattern?.test(value)) {
                          return;
                        }
                        setImpressumState((prev) => ({
                          ...prev,
                          [e]: parseFloat(value),
                        }));
                      }}
                    />
                  </FloatLabel>
                );
              })}
          </div>
        </CollapseItem>
      </Box>
    );
  }
);

export default Pramien;
