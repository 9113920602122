import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useMessageContext } from "../../../../context/messageContext";
import Box from "../Layout/Box";
import SwitchTitle from "../shared/SwitchTitle";
import ClipboardButtonsBar from "../../../Dashboard/kampaign/ClipboardButtonsBar";
import Inputs from "../../shared/Input";
import { phoneRegex } from "../../../../utils/validation";

const WhatsAppConfig = forwardRef(
  (
    { landingPageData: { whatsappNumber, whatsappText, activateWhatsApp } },
    ref
  ) => {
    const { showMessage } = useMessageContext();
    const [whatsappNumberState, setWhatsappNumberState] =
      useState(whatsappNumber);
    const [whatsappTextState, setwhatsappTextState] = useState(whatsappText);
    const [activateWhatsAppState, setActivateWhatsappState] =
      useState(activateWhatsApp);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("WhatsAppConfig.save()");
        // Regex to validate phone number format
        const deactivate = !phoneRegex.test(whatsappNumberState) && activateWhatsAppState;
        const changeTo49 = phoneRegex.test(whatsappNumberState) && whatsappNumberState.startsWith("0");
        let numberToSend = whatsappNumberState

        if (deactivate) {
          showMessage(
            "error",
            "WhatsApp deaktiviert: Bitte gültige Nummer einfügen."
          );
          setActivateWhatsappState(false);
        }

        if(changeTo49) {
          showMessage(
            "info",
            "WhatsApp-Nummer verändert: +49"
          );
          numberToSend = `+49 ${whatsappNumberState.substring(1)}`;
          setWhatsappNumberState(numberToSend);
        }

        return {
          landingPageData: {
            whatsappNumber: numberToSend,
            whatsappText: whatsappTextState,
            activateWhatsApp: deactivate ? false : activateWhatsAppState,
          },
        };
      },
    }));

    return (
      <Box>
        <SwitchTitle
          checked={activateWhatsAppState}
          onChange={(e) => setActivateWhatsappState(e)}
        >
          WhatsApp-Einstellungen
        </SwitchTitle>

        {/* Clipboard */}
        <ClipboardButtonsBar
          full={true}
          names={["Promoter Vorname", "Promoter Nachname"]}
        />

        {/** Text */}
        <Inputs
          tel={true}
          value={whatsappNumberState}
          onChange={(e) => setWhatsappNumberState(e.target.value)}
          label={"Deine WhatsApp Telefonnummer"}
          emptyCheck={activateWhatsAppState}
        />
        <Inputs
          value={whatsappTextState}
          onChange={(e) => setwhatsappTextState(e.target.value)}
          label={"Vorbelegter Einleitungssatz"}
          emptyCheck={activateWhatsAppState}
        />
      </Box>
    );
  }
);

export default WhatsAppConfig;
