import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Box from "../Layout/Box";
import MediaUpload from "../shared/MediaUpload";
import SwitchTitle from "../shared/SwitchTitle";
import { useMessageContext } from "../../../../context/messageContext";

const ClosingVideoConfig = forwardRef(
  (
    {
      landingPageData: {
        closingVideo,
        closingVideoThumbnail,
        activateClosingVideo,
      },
    },
    ref
  ) => {
    const [closingVideoState, setClosingVideoState] = useState(closingVideo);
    const [activateClosingVideoState, setActivateClosingVideoState] =
      useState(activateClosingVideo);
    const [closingVideoThumbnailState, setClosingVideoThumbnailState] =
      useState(closingVideoThumbnail);
    const closingVideoRef = useRef(null);
    const closingVideoThumbnailRef = useRef(null);
    const { showMessage } = useMessageContext();


    useImperativeHandle(ref, () => ({
      async save() {
        console.log("ClosingVideoConfig.save()");

        const [newClosingVideoUrl, newClosingVideoThumbnail] =
          await Promise.all([
            closingVideoRef.current.saveLocalToCloud(),
            closingVideoThumbnailRef.current.saveLocalToCloud(),
          ]);

        const deactivate = activateClosingVideoState && newClosingVideoUrl?.unchanged_secure_url === ""
        

        if(deactivate) {
          showMessage(
            "error",
            "Abschlussvideo deaktiviert: Bitte Video einfügen."
          );
          setActivateClosingVideoState(false)
        }
          

        return {
          landingPageData: {
            ...(newClosingVideoUrl.success && {
              closingVideo: newClosingVideoUrl.secure_url,
            }),
            ...(newClosingVideoThumbnail.success && {
              closingVideoThumbnail: newClosingVideoThumbnail.secure_url,
            }),
            activateClosingVideo: deactivate ? false : activateClosingVideoState
          },
        };
      },
    }));

    return (
      <Box>
        <SwitchTitle
          checked={activateClosingVideoState}
          onChange={(e) => {
            setActivateClosingVideoState(e);
          }}
        >
          Abschlussvideo
        </SwitchTitle>
        <MediaUpload
          ref={closingVideoRef}
          type="video"
          url={closingVideoState}
          setUrl={setClosingVideoState}
          id="closing-video"
          textSrcThere="Video ändern"
          textSrcNotThere="Video hochladen"
          full={true}
          emptyCheck={activateClosingVideoState}
        />
        <div className="text-base font-semibold text-center lg:text-9xl sm: sm:text-xl">
          Laden Sie ein ansprechendes Miniaturbild hoch, das zur Darstellung der
          Videovorschau verwendet wird
        </div>
        <MediaUpload
          ref={closingVideoThumbnailRef}
          url={closingVideoThumbnailState}
          setUrl={setClosingVideoThumbnailState}
          id="closing-video-thumbanail"
          textSrcThere="Miniaturbild ändern"
          textSrcNotThere="Miniaturbild hochladen"
          full={true}
          emptyCheck={activateClosingVideoState}
        />
      </Box>
    );
  }
);

export default ClosingVideoConfig;
