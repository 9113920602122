import React from "react";
import ClipboardButton from "../../shared/ClipboardButton";

const ClipboardButtonsBar = ({ names, full = true }) => {
  return (
    <div className={`flex ${!full ? "inline w-min" : ""} p-3 overflow-auto shadow-inner rounded-3xl whitespace-nowrap`}>
      {names.map((name,i) => <ClipboardButton key={i} name={name} />)}
    </div>
  );
};

export default ClipboardButtonsBar;
